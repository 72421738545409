import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { t } from "i18next";

import { get, post, urls } from '../../utils/api';
import {
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  FETCH_PROFILE_REVIEWS_ADD_SUCCESS,
  FETCH_PROFILE_REVIEWS_ERROR,
  FETCH_PROFILE_REVIEWS_INIT_SUCCESS,
  FETCH_PROFILE_REVIEWS_REQUEST,
} from "./profileConstants";
import { PROFILE_REVIEW_PER_PAGE } from "../../app/constants/pagination";
import buildUrlWithQueries from "../../utils/helpers/buildUrlWithQuery";

import { showErrorNotification, showSuccessNotification } from "../app/appActions";

const fetchProfileReviewSaga = function* () {
  try {
    const loadedProfileReviews = yield select(({ profile }) => profile.reviews);
    const pageOrComputed = Math.ceil(loadedProfileReviews.length / PROFILE_REVIEW_PER_PAGE);

    const query = {
      skip: pageOrComputed * PROFILE_REVIEW_PER_PAGE,
      limit: PROFILE_REVIEW_PER_PAGE,
    };

    const { data } = yield call(get, buildUrlWithQueries(urls.userReview, query));

    yield put({
     type: loadedProfileReviews * PROFILE_REVIEW_PER_PAGE === 0 ? FETCH_PROFILE_REVIEWS_INIT_SUCCESS : FETCH_PROFILE_REVIEWS_ADD_SUCCESS,
     payload: data,
    })
  } catch (e) {
    yield put({
      type: FETCH_PROFILE_REVIEWS_ERROR,
      error: e.data
    })
  }
};

const changePasswordSaga = function* ({ payload }) {
  try {

    yield call(post, urls.changePass, payload);

    yield put({
      type: CHANGE_PASSWORD_SUCCESS,
    });

    yield put(showSuccessNotification({text: t('profile.notificationBar.successChangePass')}));
  } catch (e) {
    yield put({
      type: CHANGE_PASSWORD_ERROR,
      error: e.data
    });
    yield put(showErrorNotification({text: t([`general.serverErrorsByCodes.${e.data.error.messageCode}`, 'profile.notificationBar.errorChangePass'])}));
  }
};

export default function* profileSaga() {
  yield all([
    takeEvery(FETCH_PROFILE_REVIEWS_REQUEST, fetchProfileReviewSaga),
    takeEvery(CHANGE_PASSWORD_REQUEST, changePasswordSaga),
  ])

}
