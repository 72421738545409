import styled from 'styled-components';
import * as R from 'ramda';

import { getScreenSize } from '../../utils/theme';
/*
* Reusable wrapper in order to not use div
* */
export const DisplayFlexS = styled.div`
  width: ${R.propOr('100%', 'width')};

  display: flex;
  flex-direction: ${R.propOr('row', 'direction')};
  justify-content: ${R.propOr('flex-start', 'justify')};
  flex-basis: ${R.propOr('auto', 'basis')};
  align-items: ${R.propOr('flex-start', 'aItems')};
  flex-wrap: ${R.propOr('nowrap', 'wrap')};
  
  @media (max-width: ${getScreenSize(['medium'])}) {
    flex-direction: ${R.propOr('row', 'directionScreenMedium')};
    justify-content: ${R.propOr('flex-start', 'justifyScreenMedium')};
    align-items: ${R.propOr('flex-start', 'alignItemsScreenMedium')};
  }
`;