import Modal from 'styled-react-modal';
import styled from 'styled-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getThemeColor } from '../../../utils/theme';

export const StyledModal = Modal.styled`
  width: 30rem;
  height: auto;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${getThemeColor(['white'])};
  position: relative;
`;

export const CloseIconS = styled(FontAwesomeIcon)`
  color: rgba(0, 0, 0, 0.79);
  position: absolute;
  top: 10px;
  right: 10px;
  
  &:hover {
    cursor: pointer;
  }
`;