import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import * as R from "ramda";

import { CloseIconS, StyledModal } from '../modalCommonS';
import { PrimaryButtonS, GreyBtnS, Text, TextInput } from '../../common';
import { getScreenSize, getThemeColor, getThemeFont, getThemeSize } from '../../../../utils/theme';
import { DisplayFlexS } from '../../../../utils/stylesHelpers';

const ChangePassWrapperS = styled(StyledModal)`
  justify-content: center; 
  padding: 0;
  width: 37rem;
`;

const HeaderS = styled(Text)`
  margin: 25px auto 5px;
  font-size: ${getThemeSize(['headers', 'authModal'])}; 
  color: ${getThemeColor(['violet'])};
  font-family: ${getThemeFont(['bold'])};
`;

const InputWrapper = styled.div`
  width: ${R.propOr('100%', 'width')};
  margin-bottom: 20px;
  flex-basis: 100%;
  
  @media (max-width: ${getScreenSize(['medium'])}) {
     margin-bottom: 10px;
     flex-basis: ${R.propOr('100%', 'mobileWidth')};
     width: 100%;
  }
`;

const ButtonWrapper = styled(DisplayFlexS)`
  justify-content: center;
  align-items: center;
 
  margin-top: 20px;
  
  @media (max-width: ${getScreenSize(['medium'])}) {
   justify-content: center;
  }
`;

const FormChangePass = styled.form`
  width: 100%;
  padding: 35px;
  
  @media (max-width: ${getScreenSize(['medium'])}) {
     padding: 30px 20px;
  }
`;

export const ChangePassComponent = ({
  isModalOpen,
  toggleModal,
  onSubmit,
  values,
  onChange,
  onBlur,
  errors,
  touched,
  isAdmin
}) => (
  <ChangePassWrapperS
    isOpen={isModalOpen}
    onBackgroundClick={() => toggleModal(false)}
    onEscapeKeydown={() => toggleModal(false)}
  >
    <HeaderS>
      <Trans i18nKey="modals.changePass.header" />
    </HeaderS>
      <FormChangePass {...{ onSubmit }} >
        <DisplayFlexS directionScreenMedium='column' direction='column' width='100%'>
          <InputWrapper width='100%' >
            <TextInput
              label={t('modals.changePass.form.oldPass.label').toUpperCase()}
              name="oldPass"
              placeholder={t('modals.changePass.form.oldPass.placeHolder')}
              type="password"
              value={ values.oldPass }
              {...{ onChange, onBlur }}
              isError={R.prop('oldPass', errors) && R.prop('oldPass', touched)}
              error={errors.oldPass}
            />
          </InputWrapper>
          <InputWrapper>
            <TextInput
              label={t('modals.changePass.form.newPass.label').toUpperCase()}
              name="newPass"
              placeholder={t('modals.changePass.form.newPass.placeHolder')}
              type="password"
              value={values.newPass}
              {...{ onChange, onBlur }}
              isError={R.prop('newPass', errors) && R.prop('newPass', touched)}
              error={errors.newPass}
            />
          </InputWrapper>
          {
            !isAdmin &&
            <InputWrapper>
              <TextInput
                label={t('modals.changePass.form.repeatNewPass.label').toUpperCase()}
                name="repeatNewPass"
                placeholder={t('modals.changePass.form.repeatNewPass.placeHolder')}
                type="password"
                value={ values.repeatNewPass }
                {...{ onChange, onBlur }}
                isError={R.prop('repeatNewPass', errors) && R.prop('repeatNewPass', touched)}
                error={ errors.repeatNewPass }
              />
            </InputWrapper>
          }
          {
            isAdmin
              ? (<DisplayFlexS justify="space-between">
                  <GreyBtnS
                    type="button"
                    onClick={() => toggleModal(false)}
                  >
                    <Trans i18nKey='modals.changePass.cancelBtn'/>
                  </GreyBtnS>
                  <PrimaryButtonS type='submit'>
                    <Trans i18nKey='modals.changePass.changeBtn'/>
                  </PrimaryButtonS>
                </DisplayFlexS>)
              :
              <ButtonWrapper>
                <PrimaryButtonS type='submit' padding='8px 40px'>
                  <Trans i18nKey='modals.changePass.submitBtn'/>
                </PrimaryButtonS>
              </ButtonWrapper>
          }

        </DisplayFlexS>
      </FormChangePass>
    <CloseIconS onClick={() => toggleModal(false)} icon='times'/>
  </ChangePassWrapperS>
);


