import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import i18n from 'i18next';
import { compose } from 'recompose';

import { Divider, LinkS, LinkTextS, Logo, Text } from '../common';
import { DisplayFlexS } from '../../../utils/stylesHelpers';
import { routes } from "../../routes/routesConfig";
import { getScreenSize, getThemeColor, getThemeSize } from '../../../utils/theme';
import { withLanguageOnChange } from "../../../utils/enhancers/withLanguageChange";
import { socialLinkGA } from "../../../utils/GA";
import { socialGA } from "../../constants/googleAnalytics";


const menuPart = [
  {
    transKey: 'footer.footerMenuPart.contacts',
    linkTo: routes.CONTACTS
  },
  {
    transKey: 'footer.footerMenuPart.blog',
    linkTo: 'http://blog.treuhand-suche.ch',
    tracking: socialLinkGA(socialGA.BLOG, 'http://blog.treuhand-suche.ch'),
    redirect: true
  },
  {
    transKey: 'footer.footerMenuPart.fb',
    linkTo: 'https://www.facebook.com/Treuhand-suchech-2059437657690758/',
    redirect: true,
  },
  // TODO: will be used in the future
  // {
  //   transKey: 'footer.footerMenuPart.linkedIn',
  //   linkTo: 'https://www.linkedin.com/company/19082161/admin/?welcome=true',
  //   redirect: true,
  // },
];

const lowPart = [
  {
    transKey: 'footer.footerLowPart.copyRight',
    linkTo: '/'
  },
  {
    transKey: 'footer.footerLowPart.rights',
    linkTo: '/',
    noLink: true,
  },
  {
    transKey: 'footer.footerLowPart.terms.dataProtection',
    linkTo: '/data-protection'
  },
  {
    transKey: 'footer.footerLowPart.terms.agb',
    linkTo: '/agb'
  },
];

const WrapperS = styled.div`
  flex-shrink: 1;
  flex-basis: 80px;
  align-self: center;
    
  width: 100%;
  
  margin: 0 auto;
  
  background-color: ${getThemeColor(['greyLight'])};
`;

const MenuS = styled.ul`
  display: flex;
  justify-content: space-around;
  width: ${R.propOr('auto', 'width')};
  
  @media (max-width: ${getScreenSize(['medium'])}) {
    width: 100%;
    flex-direction: ${R.propOr('column', 'flexDirectionMeduimScreens')};
    align-items: center;
    
    li {
      margin: 10px;
    }
  }
`;

const FooterTopPartS = styled.div`
  width: ${getScreenSize(['tabletPC'])};
  margin: 0 auto;

  display: flex;
  justify-content: space-between;
  align-items: center;
  
  padding: 50px 0 15px;
    
  @media (max-width: ${getScreenSize(['tabletPC'])}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    margin: 0 auto;
    width: 100%;
    padding: 55px 10px 15px
  }
  
  @media (max-width: ${getScreenSize(['medium'])}) {
    height: auto;
    width: 100%;
    
    flex-direction: column;
    align-items: center;
    
    padding: 0;
  }
`;

const FooterBottomPartS = styled.div`
  width: ${getScreenSize(['tabletPC'])};

  height: 60px;
  margin: 0 auto;
  border-top: 2px solid ${getThemeColor(['grayBlue'])};
  padding: 10px 0;
  display:flex;
  align-items: center;
  
   @media (max-width: ${getScreenSize(['tabletPC'])}) {
    width: 100%;
  }
  
  @media (max-width: ${getScreenSize(['medium'])}) {
    height: auto;
  }
`;

const FooterDividerS = styled(Divider)`
  display: none;
  
  @media (max-width: ${getScreenSize(['medium'])}) {
    display: block;
  }
`;

const LinkFooterTextS = styled(Text)`
  margin: ${R.prop('margin')};
  color: ${getThemeColor(['greyViolet'])};
  font-size: ${getThemeSize(['normalText'])};
`;

const LinkRedirect = styled(LinkS)`
  &:hover span {
    color: ${getThemeColor(['violet'])};
  }
`;

const LinkMenu = styled.a`
  &:hover span {
    color: ${getThemeColor(['violet'])};
  }
`;

const Footer = () => (
  <WrapperS>
    <FooterTopPartS>
      <Logo style={{ margin: '10px 0' }}/>
      <FooterDividerS />
        <MenuS width="60%">
        {
          menuPart.map(({ transKey, linkTo, redirect, tracking }) => (
            <li key={transKey} >
              {
                redirect ? (
                  <LinkMenu href={linkTo} style={{ textDecoration: 'none' }} onClick={() => tracking ? tracking() : null}>
                    <LinkTextS>
                      {i18n.t(transKey)}
                    </LinkTextS>
                  </LinkMenu>
                  ) : (

                    <LinkS to={linkTo}>
                      <LinkTextS>
                        {i18n.t(transKey)}
                      </LinkTextS>
                    </LinkS>
                )
              }

            </li>
          ))
        }
      </MenuS>
    </FooterTopPartS>
    <FooterBottomPartS>
      <DisplayFlexS directionScreenMedium="column" justify="space-between">
        <MenuS width="25%">
          {
            lowPart.slice(2, 4).map(({ transKey, linkTo }) => (
              <LinkRedirect key={transKey} to={linkTo}>
                <LinkFooterTextS>
                  {i18n.t(transKey)}
                </LinkFooterTextS>
              </LinkRedirect>
            ))
          }
        </MenuS>
        <FooterDividerS />
        <MenuS flexDirectionMeduimScreens="row" width="66%">
          {
            lowPart.slice(0, 2).map(({ transKey }) => (
              <li key={transKey} >
                <LinkFooterTextS size="normalText">
                  {i18n.t(transKey)}
                </LinkFooterTextS>
              </li>
            ))
          }
        </MenuS>
      </DisplayFlexS>
    </FooterBottomPartS>
  </WrapperS>
);

export default compose(withLanguageOnChange)(Footer);
