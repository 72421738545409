import {
  CLEAN_UP_AUTH_ERRORS,
  DELETE_AVATAR_ACTIVE_USER_REQUEST,
  GET_ACTIVE_USER_SUCCESS,
  LOGIN_ERROR,
  LOGIN_REQUEST,
  LOGIN_SUCCESS, LOGOUT_ADMIN_SUCCESS,
  LOGOUT_ERROR,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  RESET_PASS_ERROR,
  RESET_PASS_REQUEST,
  SEND_EMAIL_ERROR,
  SEND_EMAIL_REQUEST,
  SIGN_UP_ERROR,
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
  UPDATE_ACTIVE_USER_COMPANY_SUCCESS,
  UPDATE_ACTIVE_USER_ERROR,
  UPDATE_ACTIVE_USER_REQUEST,
  UPDATE_ACTIVE_USER_SUCCESS,
  UPDATE_AVATAR_ACTIVE_USER_ERROR,
  UPDATE_AVATAR_ACTIVE_USER_REQUEST,
  UPDATE_AVATAR_ACTIVE_USER_SUCCESS,
} from './authConstants';

const initialState = {
  token: null, // TODO: split token into two for admin and for client user.
  isRegistrated: false,
  activeUser: null,
  registrationError: null,
  loginError: null,
  resetError: null,
  emailError: null,
  updateActiveUser: null,
  updatingActiveUser: false,
};

export default function reducer(state = initialState, action) {
  const { type, error, payload } = action;

  switch (type) {
    case SIGN_UP_REQUEST: {
      return {
        ...state,
        isRegistrated: false,
        registrationError: null
      }
    }
    case LOGIN_REQUEST:
    case LOGOUT_REQUEST:
    case LOGOUT_ADMIN_SUCCESS: {
      return {
        ...state,
        loginError: null,
      }
    }
    case RESET_PASS_REQUEST: {
      return {
        ...state,
        resetError: null,
      }
    }
    case UPDATE_ACTIVE_USER_REQUEST:
    case UPDATE_AVATAR_ACTIVE_USER_REQUEST:
    case DELETE_AVATAR_ACTIVE_USER_REQUEST: {
      return{
        ...state,
        updatingActiveUser: true
      }
    }
    case UPDATE_AVATAR_ACTIVE_USER_SUCCESS: {
      return{
        ...state,
        activeUser: {
          ...state.activeUser,
          logo: payload,
        },
        updatingActiveUser: false
      }
    }
    case UPDATE_ACTIVE_USER_SUCCESS: {
      return{
        ...state,
        activeUser: payload,
        updatingActiveUser: false
      }
    }
    case UPDATE_ACTIVE_USER_COMPANY_SUCCESS: {
      return{
        ...state,
        activeUser: payload,
      }
    }
    case SEND_EMAIL_REQUEST: {
      return {
        ...state,
        emailError: null,
      }
    }
    case SIGN_UP_SUCCESS: {
      return {
        ...state,
        isRegistrated: true,
      }
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        token: payload.accessToken // also in return we have expires in, think about some security later
      }
    }
    case LOGOUT_SUCCESS: {
      return {
        ...state,
        token: null,
        activeUser: null
      }
    }
    case GET_ACTIVE_USER_SUCCESS: {
      return {
        ...state,
        activeUser: payload,
      }
    }
    case LOGIN_ERROR:
    case LOGOUT_ERROR: {
      return {
        ...state,
        loginError: error
      }
    }
    case SIGN_UP_ERROR: {
      return {
        ...state,
        isRegistrated: false,
        registrationError: error
      }
    }
    case RESET_PASS_ERROR: {
      return {
        ...state,
        resetError: error,
      }
    }
    case SEND_EMAIL_ERROR: {
      return {
        ...state,
        emailError: error,
      }
    }
    case UPDATE_ACTIVE_USER_ERROR:
    case UPDATE_AVATAR_ACTIVE_USER_ERROR: {
      return {
        ...state,
        updateActiveUser: error,
        updatingActiveUser: false,
      }
    }
    case CLEAN_UP_AUTH_ERRORS: {
      return {
        ...state,
        registrationError: null,
        loginError: null,
        resetError: null,
        emailError: null,
      }
    }
    default:
      return state;
  }

}
