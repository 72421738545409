import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { goBack } from 'connected-react-router';
import { t } from "i18next";

import {
  CREATE_COMPANY_REVIEW_ERROR,
  CREATE_COMPANY_REVIEW_REQUEST,
  CREATE_COMPANY_REVIEW_SUCCESS,
  CREATE_OWNER_ANSWER_ERROR,
  CREATE_OWNER_ANSWER_REQUEST,
  CREATE_OWNER_ANSWER_SUCCESS,
  FETCH_COMPANY_REVIEWS_ADD_SUCCESS,
  FETCH_COMPANY_REVIEWS_ERROR,
  FETCH_COMPANY_REVIEWS_INIT_SUCCESS,
  FETCH_COMPANY_REVIEWS_REQUEST,
  FETCH_UNVERIFIED_COMMENTS_REQUEST,
  FETCH_UNVERIFIED_COMMENTS_SUCCESS,
  FETCH_UNVERIFIED_COMMENTS_ERROR,
  FETCH_UNHANDLED_COMMENTS_COUNTS_SUCCESS,
  FETCH_UNHANDLED_COMMENTS_COUNTS_REQUEST,
  UPDATE_COMPANY_RATING_REQUEST,
  POST_COMMENT_BY_ADMIN_REQUEST,
  POST_COMMENT_BY_ADMIN_ERROR,
  POST_COMMENT_BY_ADMIN_SUCCESS,
  POST_COMMENT_ANSWER_BY_ADMIN_SUCCESS,
  EDIT_COMMENT_BY_ADMIN_REQUEST,
  EDIT_COMMENT_BY_ADMIN_ERROR,
  EDIT_COMMENT_BY_ADMIN_SUCCESS,
  DELETE_COMMENT_BY_ADMIN_REQUEST,
  DELETE_COMMENT_BY_ADMIN_ERROR,
  DELETE_COMMENT_BY_ADMIN_SUCCESS,
  DELETE_COMMENT_ANSWER_BY_ADMIN_SUCCESS,
  EDIT_COMMENT_ANSWER_BY_ADMIN_SUCCESS,
  FETCH_UNVERIFIED_RATINGS_REQUEST,
  FETCH_UNVERIFIED_RATINGS_ERROR,
  FETCH_UNVERIFIED_RATINGS_SUCCESS,
  DELETE_RATING_BY_ADMIN_REQUEST,
  DELETE_RATING_BY_ADMIN_SUCCESS,
  DELETE_RATING_BY_ADMIN_ERROR, POST_RATING_BY_ADMIN_SUCCESS, POST_RATING_BY_ADMIN_ERROR, POST_RATING_BY_ADMIN_REQUEST
} from './reviewConstants';
import { toggleReviewAlert } from './reviewAction';

import { get, patch, post, putApi, remove, urls } from '../../utils/api';
import buildUrlWithQueries from "../../utils/helpers/buildUrlWithQuery";

import { REVIEW_PER_PAGE } from "../../app/constants/pagination";
import { showErrorNotification, showSuccessNotification } from "../app/appActions";
import { fetchCompanies } from "../company/companyActions";

const fetchCompanyReviewsSaga = function* ({ payload }) {

  const loadedReviews = yield select(({ review }) => review.reviews.filter( item => item.belongTo === payload));
  const pageOrComputed = Math.ceil(loadedReviews.length / REVIEW_PER_PAGE);

  const query = {
    skip: pageOrComputed * REVIEW_PER_PAGE,
    limit: REVIEW_PER_PAGE,
  };

  try {
    const { data } = yield call(get, buildUrlWithQueries(urls.companyReviews(payload), query));

    yield put({
      type:  pageOrComputed * REVIEW_PER_PAGE === 0 ? FETCH_COMPANY_REVIEWS_INIT_SUCCESS : FETCH_COMPANY_REVIEWS_ADD_SUCCESS,
      payload: data,
    });

  } catch (err) {
    yield put({
      type: FETCH_COMPANY_REVIEWS_ERROR,
      error: err.data
    })
  }
};

const createCompanyReviewSaga = function* ({ payload }) {
  try {
    const review  = yield call(post, urls.createCompanyReview, payload);

    // if avatar is loaded from file
    if (payload.avatar.get('avatar')) {
      yield call(post, urls.createLogoCompanyReview(review.data._id), payload.avatar)
    }

    yield put({ type: CREATE_COMPANY_REVIEW_SUCCESS });
    yield put(goBack());
    yield put(toggleReviewAlert(true));

  } catch (err) {
    yield put({
      type: CREATE_COMPANY_REVIEW_ERROR,
      error: err.data
    });
    yield put(showErrorNotification({text: t('reviewCreate.notificationBar.error')}));
  }
};

const createOwnerAnswerSaga = function* ({ payload }) {
  try {
    const { data } = yield call(patch, urls.createOwnerAnswer, payload);

    yield put({
      type: CREATE_OWNER_ANSWER_SUCCESS,
      payload: data,
    });
    yield put(showSuccessNotification({text: t('reviewCreate.notificationBar.success')}));
    yield put(goBack());

  } catch (err) {
    yield put({
      type: CREATE_OWNER_ANSWER_ERROR,
      error: err.data
    });
    yield put(showErrorNotification({text: t('reviewCreate.notificationBar.error')}));
  }
};

const updateCompanyRating = function* ({ payload }) {
  try {
    const { score, belongTo, createdBy } = payload;
    yield call(post, urls.setRating, { score, belongTo, createdBy });

    yield put(fetchCompanies(0));

    yield put(goBack());
    yield put(toggleReviewAlert(true));

  } catch (err) {
    yield put(showErrorNotification({text: t('reviewCreate.notificationBar.error')}));
  }
};

const fetchUnverifiedCommentsListSaga = function* ({ payload: { page, type, commentsPerView, skip } }) {
  try {
    const loadedComments = yield select(({ review }) => review.unverifiedComments);

    const query = {
      skip: skip === 0 ? 0 : loadedComments.length,
      limit: page * commentsPerView,
    };

    if (type) {
      query.type = type
    }

    const { data } = yield call(get, buildUrlWithQueries(urls.unverifiedCommentsList, query));

    yield put({
      type:  FETCH_UNVERIFIED_COMMENTS_SUCCESS,
      payload: { data: data.comments, total: data.totalAmount, replaceExisting: skip === 0 },
    });

  } catch (err) {
    yield put({
      type: FETCH_UNVERIFIED_COMMENTS_ERROR,
      error: err.data
    })
  }
};

const fetchUnverifiedRatingsListSaga = function* ({ payload: { page, ratingPerView, skip } }) {
  try {
    const loadedRating = yield select(({ review }) => review.unverifiedRatings);

    const query = {
      skip: skip === 0 ? 0 : loadedRating.length,
      limit: page * ratingPerView,
    };

    const { data } = yield call(get, buildUrlWithQueries(urls.setRating, query));

    yield put({
      type:  FETCH_UNVERIFIED_RATINGS_SUCCESS,
      payload: { data: data.ratings, total: data.totalRating, replaceExisting: skip === 0 },
    });

  } catch (err) {
    yield put({
      type: FETCH_UNVERIFIED_RATINGS_ERROR,
      error: err.data
    })
  }
};

const unhandledCommentsCountsSaga = function* () {
  try {
    const { data } = yield call(get, urls.unhandledCommentsCounts);

    yield put({
      type: FETCH_UNHANDLED_COMMENTS_COUNTS_SUCCESS,
      payload: {
        unhandledAnswersCount: data.unhandledAnswersCount,
        unhandledCommentsCount: data.unhandledCommentsCount,
        unhandledRatingsCount: data.unhandledRatingsCount
      }
    })
  } catch(err) {
    console.log('something went wrong on fetching data', err); // TODO: handling error not specified.
  }
};

const postCommentByAdminSaga = function* ({ payload: { commentId } }) { // this used for comment itself and answer
  try {
    const commentToPost = yield select(({ review }) => review.unverifiedComments.find(c => c._id === commentId));

    if (commentToPost.companyAnswer) {
      yield call(patch, urls.postCommentAnswerByAdmin(commentId));

      yield put({
        type: POST_COMMENT_ANSWER_BY_ADMIN_SUCCESS,
        payload: { commentId }
      })
    } else {
      yield call(patch, urls.postCommentByAdmin(commentId));

      yield put({
        type: POST_COMMENT_BY_ADMIN_SUCCESS,
        payload: { commentId }
      })
    }

  } catch (err) {
    yield put({
      type: POST_COMMENT_BY_ADMIN_ERROR,
      payload: err.data.error
    })
  }
};

const editCommentByAdminSaga = function* ({ payload: { commentId, text } }) { // this used for comment itself and answer
  try {
    const commentToEdit = yield select(({ review }) => review.unverifiedComments.find(c => c._id === commentId));

    if (commentToEdit.companyAnswer) {
      yield call(putApi, urls.commentAnswerByAdmin(commentId), { text });

      yield put({
        type: EDIT_COMMENT_ANSWER_BY_ADMIN_SUCCESS,
        payload: { commentId, text }
      })
    } else {
      yield call(putApi, urls.commentByAdmin(commentId), { text });

      yield put({
        type: EDIT_COMMENT_BY_ADMIN_SUCCESS,
        payload: { commentId, text }
      })
    }
  } catch (err) {
    yield put({
      type: EDIT_COMMENT_BY_ADMIN_ERROR,
      payload: err.data.error
    })
  }
};

const deleteCommentSaga = function* ({ payload: { commentId } }) { // this used for comment itself and answer
  try {
    const commentToDelete = yield select(({ review }) => review.unverifiedComments.find(c => c._id === commentId));

    if (commentToDelete.companyAnswer) { // if user delete comment.
      yield call(remove, urls.commentAnswerByAdmin(commentId));

      yield put({
        type: DELETE_COMMENT_ANSWER_BY_ADMIN_SUCCESS,
        payload: { commentId }
      })
    } else {
      yield call(remove, urls.commentByAdmin(commentId));

      yield put({
        type: DELETE_COMMENT_BY_ADMIN_SUCCESS,
        payload: { commentId }
      })
    }
  } catch (err) {
    yield put({
      type: DELETE_COMMENT_BY_ADMIN_ERROR,
      payload: err.data.error
    })
  }
};

const deleteRatingSaga = function* ({ payload: { ratingId } }) {
  try {
    yield call(remove, urls.deleteRatingByAdmin(ratingId));

    yield put({
      type: DELETE_RATING_BY_ADMIN_SUCCESS,
      payload: { ratingId }
    })
  } catch (err) {
    yield put({
      type: DELETE_RATING_BY_ADMIN_ERROR,
      payload: err.data.error
    })
  }
};

const postRatingByAdminSaga = function* ({ payload: { ratingId } }) { // this used for comment itself and answer
  try {
      yield call(post, urls.postRatingByAdmin(ratingId));

      yield put({
        type: POST_RATING_BY_ADMIN_SUCCESS,
        payload: { ratingId }
      })

  } catch (err) {
    yield put({
      type: POST_RATING_BY_ADMIN_ERROR,
      payload: err.data.error
    })
  }
};

export default function* companySaga() {
  yield all([
    takeEvery(FETCH_COMPANY_REVIEWS_REQUEST, fetchCompanyReviewsSaga),
    takeEvery(CREATE_COMPANY_REVIEW_REQUEST, createCompanyReviewSaga),
    takeEvery(CREATE_OWNER_ANSWER_REQUEST, createOwnerAnswerSaga),
    takeEvery(UPDATE_COMPANY_RATING_REQUEST, updateCompanyRating),
    takeEvery(FETCH_UNVERIFIED_COMMENTS_REQUEST, fetchUnverifiedCommentsListSaga),
    takeEvery(FETCH_UNHANDLED_COMMENTS_COUNTS_REQUEST, unhandledCommentsCountsSaga),
    takeEvery(POST_COMMENT_BY_ADMIN_REQUEST, postCommentByAdminSaga),
    takeEvery(EDIT_COMMENT_BY_ADMIN_REQUEST, editCommentByAdminSaga),
    takeEvery(DELETE_COMMENT_BY_ADMIN_REQUEST, deleteCommentSaga),
    takeEvery(DELETE_RATING_BY_ADMIN_REQUEST, deleteRatingSaga),
    takeEvery(POST_RATING_BY_ADMIN_REQUEST, postRatingByAdminSaga),
    takeEvery(FETCH_UNVERIFIED_RATINGS_REQUEST, fetchUnverifiedRatingsListSaga)
  ]);
}
