import { compose } from 'redux';
import { setPropTypes } from 'recompose';
import { arrayOf, element, func, oneOfType, shape, string } from 'prop-types';

const TableContainer = compose(
    setPropTypes({
        columns: arrayOf(shape({
            title: oneOfType([string, element]).isRequired,
            sort: shape({ sortFunc: func, sortState: [-1, 0, 1]}),
        })).isRequired,
        data: arrayOf(shape({
            name: oneOfType([string, element]),
            data: oneOfType([string, element]),
            location: oneOfType([string, element]),
            phone: oneOfType([string, element]),
            email: oneOfType([string, element]),
            lastChange: shape({
                lastData: oneOfType([string, element]),
                byChange: oneOfType([string, element]),
            }),
            action: oneOfType([string, element]),
        })).isRequired
    })
);

export default TableContainer
