import React, { Fragment, forwardRef } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { DisplayFlexS } from '../../../utils/stylesHelpers';
import { FieldError } from './Error';
import { getThemeColor, getThemeFont, getThemeSize } from '../../../utils/theme';

export const InputWrapper = styled.label`
  width: 100%;
  flex-basis: 100%;
  text-align: left;
  align-items: center;
  display: ${({ isHorizontal }) => isHorizontal ? 'flex' : 'inline'};
`;

export const InputLabel = styled.span`
  flex: 1;
  display: block;
  padding:  ${({ padding }) => padding || '0 0 5px'};
  
  font-weight: ${({ fontWeight }) => fontWeight || 700};
  color: ${({ labelColor }) => getThemeColor([labelColor || 'darkerViolet'])};
  font-size: ${({ fontSize }) => fontSize || getThemeSize(['normalText'])};
  font-family: ${({ fFamily }) => getThemeFont([fFamily || 'bold'])};
  line-height: 14px;
`;

export const Input = styled.input`
  outline: none;
  transition: ${R.propOr('border 250ms ease', 'transaction')};
  display: ${R.propOr('block', 'display')};
  width: ${R.propOr('100%', 'width')};
  height: ${R.propOr('', 'height')};
  padding: ${R.propOr('8px 20px', 'padding')};
  margin: ${R.propOr('0', 'margin')};
  
  font-family: ${getThemeFont(['regular'])};
  font-size: ${R.propOr(getThemeSize(['normalText']), 'fontSize')};
  color: ${getThemeColor(['greyViolet'])}; 
  
  text-align: ${R.propOr('left', 'textAlign')};
  background: ${getThemeColor(['greyBlueLight'])};
  
  border: 1px solid ${({ borderColor = getThemeColor(['greyLight']), isError }) =>
  isError ? getThemeColor(['red']) : borderColor};
  
  border-radius: ${R.propOr('2px', 'borderRadius')};
  
  &:focus {
    background-color: ${getThemeColor(['white'])};
    
    border-color: ${({ isError }) => isError ? getThemeColor(['red']) : getThemeColor(['grayBlue'])};
  }
  &::placeholder {
    color: ${getThemeColor(['greyViolet'])};
    opacity: 0.5;
  }
`;

export const TextAreaS = styled.textarea`
  width: 100%;
  padding: 8px 20px;
  resize: none;
  border: 1px solid ${({ borderColor = getThemeColor(['greyLight']), isError }) =>
  isError ? getThemeColor(['red']) : borderColor};
  border-radius: ${R.propOr('2px', 'borderRadius')};
  
  background-color: ${getThemeColor(['greyBlueLight'])};
  color: ${getThemeColor(['greyViolet'])};
  font-size: ${getThemeSize(['normalText'])};
  font-family: ${getThemeFont(['regular'])};
  transition: background-color 1s;
  
  &:focus {
    background-color: ${getThemeColor(['white'])};
  }
  
  &::placeholder {
    color: ${getThemeColor(['greyViolet'])};
    opacity: 0.5;
  }
`;

const DeleteIcon = styled(FontAwesomeIcon)`
  margin-left: 5px;
  color: ${getThemeColor(['red'])};
  
  &:hover {
    cursor: pointer;
  }
`;

export const TextInput = forwardRef(({ name, label, isHorizontal, isError, error, labelColor, closeBtnHandler, ...rest}, ref) => <Fragment>
  <DisplayFlexS justify="space-between">
    <InputWrapper {...{ isHorizontal, closeBtnHandler }}>
      <InputLabel
        labelColor={labelColor}
        fontWeight={rest.fontWeight}
        fFamily={rest.fFamily}
        htmlFor={name}
      >{label}</InputLabel>
      <DisplayFlexS aItems="center" alignItemsScreenMedium="center">
        <Input {...{ name, isError, ref }} {...rest} />
        {
          closeBtnHandler ? <DeleteIcon onClick={closeBtnHandler} icon='times'/> : null
        }
      </DisplayFlexS>
    </InputWrapper>

  </DisplayFlexS>
  {(isError && error) ? <FieldError {...{ error }} /> : null}
</Fragment>);

export const TextArea = ({ name, label, isError, error, labelColor, ...rest }) => (
  <Fragment>
    <InputLabel labelColor={labelColor} htmlFor={name}>{label}</InputLabel>
    <TextAreaS { ...{ name, isError } } {...rest} />
    {(isError && error) ? <FieldError {...{ error }} /> : null}
  </Fragment>
);
