export default {
  general: {
    back: 'Back',
    searchBtn: 'Search',
    serverErrorsByCodes: {
      3: 'Something wrong with server, please try later',
      57: 'The company with the given email does not exist',
      58: 'Wrong password',
      59: 'Admin with such mail does not exist',
      61: 'Company with given email already exist',
      62: 'Phone number os invalid. Should contains only numbers',
      64: 'Company with such name exist in the system',
      65: 'Old password is incorrect',
      /*Custom errors on client*/
      1000: 'User is not admin. '
    },
    paginator: {
      noMoreItemsMessage: 'No more items to show'
    }
  },
  header: {
    companyName: 'TreuhandSuche',
    inputPlaceholder: 'Search',
    menu: {
      blog: 'Blog',
      loginForCompanies: 'Login',
      profile: 'Profile',
      userName: 'User',
      myProfile: 'My Profile',
      myCompany: 'My Company',
      logout: 'Logout',
      forCompanies: 'For companies'
    }
  },
  footer: {
    footerMenuPart: {
      contacts: 'Contacts',
      blog: 'Blog',
      forCompanies: 'For companies',
      fb: 'Facebook',
      linkedIn: 'LinkedIn'
    },
    footerLowPart: {
      copyRight: 'treuhand-suche.ch © 2020',
      rights: 'All rights reserved',
      terms: {
        agb: 'Terms of Service',
        dataProtection: 'Privacy Policy'
      }
    }
  },
  homeFilter: {
    placeholders: {
      zipCode: 'Zip',
      city: 'Location',
      canton: 'Kanton',
      services: 'Services'
    },
    errors: {
      noCityFound: 'Not a valid location',
      noZipFound: 'Not a valid zip'
    }
  },
  modals: {
    auth: {
      registration: {
        header: 'Registration',
        userTabName: 'For users',
        companyTabName: 'For company',
        bottomCapture: {
          text: 'By clicking on the button you agree with our ',
          privacyBtn: 'Privacy Policy',
          and: ' and ',
          termsBtn: 'Terms of Use.'
        },
        backToLoginMsg: 'Have you already registered?',
        singInBtn: 'Sign in',
        notificationBar: {
          success: 'New company created',
          error: 'Registration failed'
        }
      },
      login: {
        header: 'Welcome back!',
        forgotPass: 'Forgot your password?',
        noSignedMsg: 'Not signed up yet?',
        singUpBtn: 'Sign up'
      },
      resetPass: {
        header: 'Reset your password',
        capture: 'Enter your email address below. We\'ll look for your account and send you a password reset email'
      },
      sendMail: {
        header: 'Opps, maybe your company already in the system',
        capture: 'It seems in the system we have registered such company or user with such email, enter your email and admins will contact with you'
      },
    },
    changeLogo: {
      header: 'Change Image',
      selectImage: 'Select image',
      deleteImage: 'Clear image',
      close: 'Close',
    },
    filters: {
      header: 'Filters',
      revertBtn: 'Revert',
      applyBtn: 'Apply',
      languages: {
        Deutsch: 'German',
        Englisch: 'English',
        Französisch: 'French',
        Italienisch: 'Italian ',
        Russisch: 'Russian',
        Holländisch: 'Dutch',
        Spanisch: 'Spanish',
        Portugiesisch: 'Portuguese',
        Arabisch: 'Arabian',
      }
    },
    changePass: {
      header: 'Replace password',
      form: {
        oldPass: {
          label: "Old password",
          placeHolder: "Enter old password",
          errors: {
            required: 'Password is required',
          }
        },
        newPass: {
          label: "Enter new password",
          placeHolder: "Enter new password",
          errors: {
            required: 'Password is required',
            equal: 'New password must be different',
          }
        },
        repeatNewPass: {
          label: "Repeat new password",
          placeHolder: "Confirm new password",
          errors: {
            required: 'Password is required',
            noEqual: 'Different passwords',
          }
        }

      },
      submitBtn: "Save",
      cancelBtn: "Cancel",
      changeBtn: 'Change',
    },
    writeUs: {
      header: "Write us",
      form: {
        companyName: {
          label: "Company name",
          placeHolder: "Enter company name",
          required: 'Company name is required'
        },
        firstName: {
          label: "Your Name",
          placeHolder: "Enter name",
          required: 'Your Name is required'
        },
        email: {
          label: "Email",
          placeHolder: "Enter email",
          required: "Email is required",
          isNotValid: "Email is not valid"
        },
        comment: {
          label: "Your Message",
          placeHolder: "Enter text",
          required: "Message is required",
        },
        submitBtn: "Send"
      },
      notificationBar: {
        success: "Comment sent",
        error: "An error occurred while submitting. Submission failed"
      },
    },
    reviewAlert: {
      thanksText: 'Thank you!',
      notification: 'Your feedback will be processed by our team and posted.'
    }
  },
  forms: {
    common: {
      emailForm: {
        label: 'Email',
        placeholder: 'Enter your email',
        errors: {
          required: 'Email is required',
          isNotValid: 'Email is not valid',
        },
        submitBtn: 'Submit',
      },
      name: {
        label: 'Name',
        placeholder: 'Enter your name',
        errors: {
          required: 'Name is required'
        },
      }
    },
    login: {
      labels: {
        email: 'Email',
        pass: 'Password'
      },
      placeholders: {
        email: 'Email address',
        pass: 'Enter your password'
      },
      errors: {
        email: {
          required: 'Email is required',
          isNotValid: 'Email is not valid'
        },
        pass: {
          required: 'Password is required',
        }
      },
      submitBtn: 'Login',
    },
  },
  homepage: {
    mainCapture: 'Find the right',
    mainCapturePartTwo: 'accountant',
    mainCapturePartThree: 'or',
    mainCapturePartFour: 'tax advisor',
    secondaryCapture: 'Overview of the Swiss consulting industry',
    infoBlock: {
      freeProfiles: {
        header: 'Free profile registration',
        capture: 'Join us and introduce yourself to new potential customers!'
      },
      companiesCount: {
        header: 'Over 1,500 accountants',
        capture: 'The database holds numerous contacts of accountants from German-speaking Switzerland, and updates every day.'
      },
      fields: {
        header: '{{ services }} Services',
        capture: 'Find a suitable accountant service that best addresses your need.'
      },
      locations: {
        header: 'All cantons',
        capture: 'Find an accountant or tax advisor in your area. All German-speaking cantons are included.'
      },
    },
    companiesCarousel: {
      title: 'New',
      subtitle: 'Accountants profiles',
      loadMoreBtn: 'All companies',
      goToCompanyBtn: 'To company details'
    },
    blogCarousel: {
      title: 'Blog',
      loadMoreBtn: 'All posts'
    },
    headerBeforeRegistration: 'Create your free account today',
    subHeaderBeforeRegistration: 'What are the benefits to join Treuhandsuche?',
    registrationFormTitle: 'Join us',
    registrationFormSubTitle: 'And get everything for free',
    registrationForm: {
      labels: {
        name: 'Name',
        phone: 'Phone number',
        email: 'Email',
        pass: 'Password',
        confirmPassword: "Repeat password"
      },
      placeholders: {
        name: 'Company name',
        phone: 'Phone number',
        email: 'Email address',
        pass: 'Enter your password',
        confirmPassword: 'Repeat your password',
      },
      errors: {
        name: {
          required: 'Company name is required',
        },
        phone: {
          formatIsWrong: 'Phone is not in right format',
          required: 'Phone is required',
        },
        email: {
          required: 'Email is required',
          isNotValid: 'Email is not valid'
        },
        pass: {
          required: 'Password is required',
        },
        confirmPass:{
          required: 'Confirm password is required',
          confirmPassword: 'Passwords are not the same'
        },

      },
      submitBtn: 'Next',
      successMessage: 'Company was successfully created.'
    },
    infoTextInRegistrationSection: {
      first: {
        header: 'Advertise your business',
        capture: 'Communicate your services, and offer them to a broad audience.',
      },
      second: {
        header: 'Strengthen your brand',
        capture: 'Introduce your company to a specific target group: as a local brand, as an industry expert or as a price leader.',
      },
      third: {
        header: 'Engage new customers',
        capture: 'Treuhandsuche generates direct requests from prospective customers. Acquire new customers with an attractive profile.',
      }
    },
    subscribeSection: {
      capture: 'Get informed about events and news',
      email: {
        placeholder: 'Your email',
        required: 'Email is required',
        invalid: 'Email is not valid'
      },
      emailLabel: 'Your email address',
      subscribeBtn: 'Subscribe',

    }
  },
  companies: {
    topCapture: 'Search result',
    amountOfItems: 'accountants and tax advisors in ',
    country: 'Switzerland',
    sortCapture: 'Sort: ',
    allOption: 'All',
    noCompaniesNoFoundByQuery: {
      firstPart: 'Your request could not be fulfilled...',
      secondPart: 'But we found these trustee nearby:'
    },

    filter: {
      capture: 'Filter',
      clearFilterBtn: 'Clear',
      cantonTitle: 'Canton',
      zipTitle: 'ZIP',
      servicesTitle: 'Services',
      languagesTitle: 'Languages',
      memberTitle: 'Member',
      searchPlaceholders: {
        kanton: 'Search canton',
        language: 'Search language',
        service: 'Search service',
        zip: 'ZIP search'
      },
      searchErrorMessages: {
        kantonNotFound: 'Not valid canton',
        languageNotFound: 'Not valid language',
        serviceNotFound: 'Not valid service',
        zipNotFound: 'Not valid ZIP'
      }
    },
    sorts: {
      mostPopular: 'Profile completeness',
      views: 'Popularity',
      name: 'In ABC order'
    },
    companyCard: {
      servicesLabel: 'Services',
      memberOfLabel: 'Membership',
      languageLabel: 'Language',
      viewsLabel: 'Views',
    }
  },
  companyItem: {
    goBackBtn: 'Go back',
    goBackProfile: 'Back to profile',
    companyLogoAltValue: 'Company logo',
    editBtn: 'Edit',
    verified: 'Verified',
    notVerified: 'Not verified',
    views: 'Views',
    employees: 'Employees',
    ratingCount: "{{ count }} review",
    ratingCount_plural: "{{ count }} reviews",
    ratingComponent: {
      tooltip: {
        main: 'Average rating on',
        averageTreuh: 'treuhand-suche.ch',
        averageGoogle: 'Google My Business',
        averagePortal: 'Average ratings from 2 Portalen ',
        averageTreuhandvergleich: 'treuhandvergleich.ch*',
        reviews: 'Reviews',
        desc: '*Ratings were converted into ',
        descStarSystem: 'a five-star system'
      },
      noRating: 'Not Specified'
    },
    infoItemsCategoriesName: {
      desc: 'Description',
      rate: 'Hourly rate in CHF',
      services: 'Services',
      industry: 'Industry',
      languages: 'Languages',
      member: 'Membership',
      software: 'Software',
    },
    rates: {
      secretariat: 'Secretariat',
      auditor: 'Auditor',
      accounting: 'Accounting',
      experts: 'Experts'
    },
    infoBlock: {
      header: 'Is this your company?',
      subHeader: 'Write to us, and create a free account with our moderators to use the following options:',
      createAccount: 'Contact us',
      listItem1: 'Answer the comments about your company',
      listItem2: 'Correct the information about your company',
      listItem3: 'Advertise your services',
      listItem4: 'Introduce the company´s management and employees',
      listItem5: 'Set backlink to your website',
    },
    reviewsBlock: {
      headerCapture: 'Reviews',
      createReviewBtn: 'Create review',
      answerBtn: 'Answer',
      reportBtn: 'Report',
      ownerAnswerLabel: 'Official answer by owner',
    }
  },
  companyEdit: {
    capture: 'Edit company profile',
    saveBtn: 'Save',
    addItemBtn: 'Add one',
    infoItemsCategoriesName: {
      main: 'Main info',
    },
    errorMessages: {
      name: 'Name is required',
      email: 'Email has wrong format',
      website: 'Website has wrong format',
      phone: {
        type: 'Phone number should contain only numbers'
      },
      rate: 'Value should be number',
      employeeCount: {
        type: 'Count of employee should be number'
      }
    },
    selectPlaceholders: {
      canton: 'Select canton',
      industry: 'Select industry',
      languages: 'Select language',
      member: 'Select member',
      software: 'Select software',
      services: {
        financialAccounting: 'Select financial accounting',
        consultation: 'Select consultation',
        miscellaneous: 'Select miscellaneous',
      }
    },
    labels: {
      name: 'Company name',
      canton: 'Canton',
      city: 'City',
      street: 'Street and house number',
      zip: 'ZIP',
      email: 'Email',
      phone: 'Phone number',
      employeeCount: 'Number of employees',
      website: 'Website',
      desc: 'About your company',
      industry: 'Industry',
      languages: 'Language',
      member: 'Member',
      software: 'Software',
      otherSoftware: 'Other software',
      otherIndustries: 'Industry',
      otherLanguages: 'Other languages',
      otherMembers: 'Other members',
      rate: {
        secretary: 'Secretariat',
        accountant: 'Accountant',
        auditor: 'Auditor',
        expert: 'Expert',
      },
      services: {
        financialAccounting: 'Financial Accounting',
        consultation: 'Consultation',
        miscellaneous: 'Miscellaneous',
        moreServices: 'Other services'
      }
    },
    notificationBar: {
      success: 'Profile saved',
      error: 'When saving an error has occurred. Changes failed.'
    }
  },
  reviewCreate: {
    header: 'New review',
    guestCapture: 'Guest',
    submitBtn: 'Post',
    ratingCapture: 'Rate the company',
    headersCaptures: {
      userAcc: 'User account',
      info: 'Your information',
      ownerAnswer: 'Your answer'
    },
    userDataForm: {
      labels: {
        email: 'Email',
        name: 'Name',
        text: 'Your message'
      }
    },
    notificationBar: {
      success: 'Comment sent.',
      successRating: 'Rating sent',
      error: 'An error occurred while submitting. Submission failed.'
    },
    commentPostedAfter: 'Your comment will be posted after the moderation.',
    textValidationRequired: 'Text for review is required',
    nameValidationRequired: 'User name for review is required',
  },
  profile: {
    header: 'Profile',
    profileTabs: {
      comments: 'Comments',
      settings: 'Settings',
    },
    settings: {
      selectImage: 'Select image',
    },
    labels: {
      name: 'Name',
      email: 'Email Address',
      password: 'Password'
    },
    button: {
      save: 'Save',
      editCompanyProfile: 'To company profile',
      changePassword: 'Change Password',
    },
    notificationBar: {
      successChangePass: 'Password changed',
      successUpdateUser: 'Profile saved',
      errorUpdateUser: 'When saving an error has occurred. Changes failed.',
      errorChangePass: 'The password has not been changed'
    }
  },
  logoText: {
    changeLogo: 'Change picture'
  },
  contactsPage: {
    title: 'About us',
    contact: 'Contact',
    titleForm: 'Write to us',
    submitBtn: 'Send',
    form: {
      labels: {
        name: 'Name',
        email: 'Email',
        text: 'Your message',
      },
      placeholders: {
        name: 'Enter name',
        email: 'Enter email address',
        text: 'Text message',
      },
      errors: {
        emailRequired: 'Email is required',
        nameRequired: 'Name is required',
        textRequired: 'Message is required',
        isNotValid: 'Email is not valid'
      }
    },
    notificationBar: {
      success: 'Message sent.',
      error: 'Message not sent.',
    }
  },
  forCompanies: {
    title: 'Improve your business now!',
    titleDescription: 'A mix of the most complete accountant listings in Switzerland, together with an intelligent algorithm, are key success factors in optimally matching our customers’ needs with the most appropriate consultant, and offering them better comparability. For us, the only good customer is a satisfied customer.',
    titleBtn: 'Let\'s go!',
    contentTitleOne: 'Advertising with no misuse',
    contentDescriptionOne: 'Increase your online presence and sharpen business profile with the Treuhandsuche platform. You have the unique opportunity to advance your profile recognition and acquaint others with your services. Targeted advertising – with a misuse rate reduced to minimum – allows to find (or to be found by) companies that need exactly the services you offer.',
    contentTitleTwo: 'Customer acquisition',
    contentDescriptionTwo: 'Acquire more new customers, and increase your sales. With treuhand-suche.ch, your company also has the opportunity to establish a true customer trust, and create a strong reputation.',
    singUpTitle: 'Sign up with us!',
    singUpSubTitle: 'And use all options for free!',
    singUpDescription: 'By linking your Fiduciary Search profile to your website, you can increase the web traffic ' +
      'of your website directly and indirectly through increased organic search queries in Google.',
    submitButton: 'Register',
  },
  pageNotFound: {
    title: 'The page you are looking for cannot be found',
    button: 'Back to start page'
  },
  companyLanguages: {
    'Alle': 'All',
    'Deutsch': 'German',
    'Englisch': 'English',
    'Französisch': 'French',
    'Italienisch': 'Italian',
    'Russisch': 'Russian',
    'Holländisch': 'Dutch',
    'Spanisch': 'Spanish',
    'Portugiesisch': 'Portuguese',
    'Arabisch': 'Arabian'
  },
  companyServices: {
    'Alle': 'All',
    'Laufende Verbuchungen': 'Financial accounting',
    'Geschäftsabschluss': 'Annual statements',
    'Mehrwertsteuer': 'VAT',
    'Lohnbuchhaltung': 'Payroll accounting',
    'Steuerberatung Schweiz': 'Swiss tax consultancy',
    'Juristische Beratung': 'Legal advice',
    'Unternehmensberatung': 'Business consultancy',
    'Firmengründungen': 'Company foundation',
    'Immobilienverwaltung': 'Real estate management',
    'Revision': 'Revision',
    'Steuerberatung International': 'International tax consultancy',
    'Steuererklärungen': 'Tax return',
    'Domizil-Services': 'Domicile services',
    'Vermögensverwaltung': 'Asset management',
    'Wirtschaftsprüfung': 'Financial audit',
    'Erbschaftsberatung': 'Inheritance consultancy'
  },
  findCompany: {
    companyTitleText: 'Looking for an accountant?',
    companySecondaryText: 'Do you need a consultation for a company foundation? Or do you need some help with VAT in Basel? On treuhand-suche.ch we have collected accountants and tax advisors from Zurich, Winterthur, Basel, Lucerne, Bern, Solothurn and other German-speaking cantons. \n \n You will find such services as financial accounting, accounts receivable and accounts payable, payroll accounting, human resources, annual statements, tax returns and other services. \n \n Comprehensive profiles of companies give you all the necessary information to find a suitable accountant or ask him a question directly on treuhand-suche.ch. Our platform is ideal for small and middle Swiss companies, self-employed and private persons - with us you will find the best option for your budget.'
  },

  adminPanel: {
    header: {
      addNewCompany: 'ADD NEW COMPANY',
      admins: 'Existing Administrators',
      myProfile: 'My Profile',
      logout: 'Logout'
    },
    sidebar: {
      home: 'Home',
      company: 'Company',
      newsletter: 'Newsletter',
      profile: 'Profile',
      status: 'Status',
      comments: 'Comments'
    },
    companies: {
      companyProfile: 'Company Profile',
      buttons: {
        activeCompany: 'active Company',
        deleteCompany: 'Deleted',
        editTooltip: 'EDIT',
        deleteTooltip: 'DELETE',
        restoreTooltip: 'Restore'
      },
      tableHeaders: {
        name: 'Name',
        date: 'Date',
        location: 'Location',
        phone: 'Phone',
        email: 'Email',
        lastChanges: 'Last Changes',
        action: 'Action'
      },
      totalCompany: 'Total Companies:',
      companiesRender: 'Companies',
      createDate: 'Creation date:',
      lastEdit: 'Last edited:',
      from: 'from'
    },
    comments: {
      navigationButtons: {
        all: 'All',
        comments: 'Comments',
        responses: 'Responses',
        rating: 'Rating'
      },
      controlButtons: {
        posted: 'Post',
        edit: 'Edit',
        delete: 'Delete'
      },
      modals: {
        title: 'Are you sure?',
        cancelBtn: 'Cancel',
        postBtn: 'Post',
      },
      commentsName: 'Comment',
      ratingsName: 'Rating',
      commentsResponse: 'Response to a comment',
      totalComments: 'Total',
      saveBtn: 'Save',
      commentStates: {
        deleted: 'Deleted',
        updated: 'Updated',
        posted: 'Posted'
      }
    },
    profile: {
      pageTitle: 'My Profile',
      saveBtn: 'Save',
      changePassBtn: 'Change password',
      passwordCapture: 'Password',
    },
    statuses: {
      buttons: {
        activeStatues: 'All Companies',
        verifiedStatuses: 'Verified',
        notVerifiedStatuses: 'Not Verified'
      },
      tableHeaders: {
        name: 'Name',
        verification: 'Verification'
      }
    },
    modals: {
      companyDeleteConfirmation: {
        text: 'This company profile will be permanently deleted. The procedure cannot be revoked.',
        cancelBtn: 'Cancel',
        approveBtn: 'Delete'
      },
      postCommentConfirmation: {
        text: 'Are you sure?',
        approveBtn: 'Post',
        cancelBtn: 'Cancel'
      },
      deleteCommentConfirmation: {
        text: 'This comment will be permanently deleted. The process cannot be revoked.',
        approveBtn: 'Clear',
        cancelBtn: 'Cancel'
      },
      deleteRatingConfirmation: {
        text: 'This rating will be permanently deleted. The process cannot be revoked.',
        approveBtn: 'Clear',
        cancelBtn: 'Cancel'
      }
    }
  }
}
