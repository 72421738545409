import { appName } from '../../config';

export const moduleName = 'modals';
const prefix = `${appName}/${moduleName}`;

export const MODAL_TYPES = {
  COMPANY_DELETE_CONFIRMATION: 'companyDeleteConfirmation',
  POST_COMMENT_CONFIRMATION: 'postCommentConfirmation',
  DELETE_COMMENT_CONFIRMATION: 'deleteCommentConfirmation',
  DELETE_RATING_CONFIRMATION: 'deleteRatingConfirmation',
  POST_RATING_CONFIRMATION: 'postCommentConfirmation',
};

// Const
export const SET_MODAL_STATE = `${prefix}/SET_MODAL_STATE`;
