import React from 'react';
import { connect } from 'react-redux';
import { compose, setPropTypes } from 'recompose';
import { func, oneOf } from 'prop-types';

import { modalsActions, modalsConstants } from '../../../../modules/modals';

import { CloseIconS, StyledModal } from '../modalCommonS';

import ConfirmationLayouts from './confirmationModalsLayout';

const ConfirmationModal = ({ setModalStateFor, opened, onApproved, entityId, confirmationName }) => (
  <StyledModal
    isOpen={opened}
    onBackgroundClick={() => setModalStateFor(confirmationName, { open: false })}
    onEscapeKeydown={() => setModalStateFor(confirmationName, { open: false })}
    style={{
      padding: '30px 10px'
    }}
  >

    {ConfirmationLayouts[confirmationName](setModalStateFor, confirmationName, onApproved, entityId )}

    <CloseIconS onClick={() => setModalStateFor(confirmationName, { open: false })} icon='times'/>
  </StyledModal>
);

export default compose(
  connect(({ modals }, { confirmationName }) => ({
    opened: modals[confirmationName].open,
    entityId: modals[confirmationName].id
  }),
    { setModalStateFor: modalsActions.setModalStateFor }),
  setPropTypes({
    onApproved: func,
    confirmationName: oneOf(Object.values(modalsConstants.MODAL_TYPES))
  })
)(ConfirmationModal);
