import styled from 'styled-components';
import * as R from 'ramda';

import {getThemeColor, getThemeSize} from '../../../../utils/theme';

export const TableWrapS = styled.div`
  //overflow-y: hidden; // TODO: flexible table
  width: 100%;
  max-width: ${R.propOr('100%', 'maxWidth')};
  -webkit-overflow-scrolling: touch;
`;

export const TableS = styled.table`  
  width: 100%;
  background-color: ${getThemeColor(['white'])};
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  padding: 0 15px;
  > tbody > tr > td  {
    padding: 15px 10px;
  }
`;

export const TableHeadS = styled.thead`
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
`;

export const TableHeadTHS = styled.th`
  color: ${getThemeColor(['violet'])};
  font-size: ${getThemeSize(['bigText'])};
  font-weight: normal;
  text-transform:uppercase;
  text-align: left;
  padding: 15px 10px;
`;

export const TableTitleNameS = styled.span`
  display: block;
  font-weight: bold;
  color: ${getThemeColor(['primary'])};
  text-align: left;
  text-transform: uppercase;
  max-width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const TableTextS = styled(TableTitleNameS)`
  color: #717171;
  font-weight: normal;
`;

export const TableTextDateS = styled.p`
  color: #717171;
  font-weight: normal;
`;

export const TableTextByS = styled.span`
  display:block;
  color: #717171;
  font-weight: normal;
  margin-top: 5px;
  text-transform: initial;
`;

export const TableIconsWrapS = styled(TableTitleNameS)`
  display:flex;
  align-items: center;
`;

export const TableRow = styled.tr`
  border-bottom: 1px solid ${getThemeColor(['grayBlue'])};
`;
