import React from 'react';
import { withProps } from 'recompose';
import styled from 'styled-components';
import Rating from 'react-rating';

import { getThemeSize } from '../../../utils/theme';

import StarImg from '../../assets/imgs/star-without-bg.svg';
import StartWithBg from '../../assets/imgs/star-with-bg.svg';

const MAX_RATING_VALUE = 5;

const RatingWrapS = styled.div`
  display: flex;
  align-items:center;
  
  span {
    font-size: ${({ fontSize }) => getThemeSize([fontSize || 'bigText'])};
    font-weight: normal;
  }
  
  img {
    margin-right: ${({ marginRight }) => marginRight || '10px'};
    width: ${({ widthImg }) => widthImg || '22px'};
  }
`;

/*
*
*   rating - Number
*   setRating - Func
*
* */
const RatingStars = ({
  selectedRating = 0,
  setRating,
  ratingValRef,
  maxRating = MAX_RATING_VALUE,
  showMaxRating = false,
  filledStarImg = StartWithBg,
  emptyStarImg = StarImg,
  styles,
  ...rest
}) => (
  <RatingWrapS {...styles}>
      <Rating
        start={0}
        stop={maxRating}
        step={1}
        initialRating={selectedRating}
        emptySymbol={<img src={emptyStarImg} alt="rating-start"/>}
        fullSymbol={<img src={filledStarImg} alt="rating-start"/>}
        onChange={(val) => setRating(val)}
        onHover={(rating) => ratingValRef.current && rating ? ratingValRef.current.textContent = rating || selectedRating : null}
        {...rest}
    />
    <span ref={ratingValRef}>{ selectedRating } { showMaxRating && `/${maxRating}` }</span>
  </RatingWrapS>);

export default withProps({ ratingValRef: React.createRef() })(RatingStars);
