import React from 'react';
import * as R from 'ramda';
import i18next from 'i18next';
import styled from 'styled-components';
import InputMask from 'react-input-mask';

import { FieldError, PrimaryButtonS, TextInput } from '../../common';
import { ErrorWrapper } from "../../common/Error";
import { getThemeColor } from "../../../../utils/theme";


const customizeInput = {
  borderRadius: "0",
  'box-shadow': "none",
  padding: '8px 0 8px 15px'

};

const Form = styled.form`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const InputWrapper = styled.div`
  width: 100%;
  min-height: ${({showEmptyField}) => showEmptyField ? '70px' : "50px"};
  display: ${({ isForCompany  }) => isForCompany ? 'none': 'block' };
`;

const PasswordErrorWrapper = styled(ErrorWrapper)`
  display: block;
  float: left; 
  clear: left;
  color: ${getThemeColor(['red'])};
`;


export const RegistrationFormComponent = ({
  values,
  errors,
  touched,
  onSubmit,
  onChange,
  onBlur,
  isSubmitting,
  serverError,
  isForCompany,
  submitBtnName,
  withNotification
}) => (
  <Form {...{ onSubmit }}>
    <FieldError error={!withNotification ? (serverError ? i18next.t(`general.serverErrorsByCodes.${serverError.messageCode}`) : null) : null} />
    <InputWrapper isForCompany={isForCompany} showEmptyField>
      <TextInput
        label={i18next.t('homepage.registrationForm.labels.name').toUpperCase()}
        name="name"
        type="text"
        placeholder={i18next.t('homepage.registrationForm.placeholders.name')}
        value={R.prop('name', values)}
        {...{ onChange, onBlur }}
        isError={R.prop('name', errors) && R.prop('name', touched)}
        error={errors.name}
        { ...customizeInput }
      />
    </InputWrapper>

    <InputWrapper isForCompany={isForCompany} showEmptyField >
      <InputMask  mask="\099 999 99 99" maskChar="X" value={R.prop('phone', values)} onChange={onChange} onBlur={onBlur}>
        {maskedInputProps => <TextInput
          label={i18next.t('homepage.registrationForm.labels.phone').toUpperCase()}
          name="phone"
          type="text"
          placeholder={i18next.t('homepage.registrationForm.placeholders.phone')}
          {...{ ...maskedInputProps }}
          isError={R.prop('phone', errors) && R.prop('phone', touched)}
          error={errors.phone}
          { ...customizeInput }
        />}
      </InputMask>
    </InputWrapper>

    <InputWrapper showEmptyField >
      <TextInput
        label={i18next.t('homepage.registrationForm.labels.email').toUpperCase()}
        name="email"
        type="text"
        placeholder={i18next.t('homepage.registrationForm.placeholders.email')}
        value={R.prop('email', values)}
        {...{ onChange, onBlur }}
        isError={R.prop('email', errors) && R.prop('email', touched)}
        error={errors.email}
        { ...customizeInput }
      />
    </InputWrapper>


    <InputWrapper >
      <TextInput
        label={i18next.t('homepage.registrationForm.labels.pass').toUpperCase()}
        name="password"
        type="password"
        placeholder={i18next.t('homepage.registrationForm.placeholders.pass')}
        value={R.prop('password', values)}
        {...{ onChange, onBlur }}
        { ...customizeInput }
      />
    </InputWrapper>

    <InputWrapper showEmptyField>
      <TextInput
        name="confirmPassword"
        type="password"
        placeholder={i18next.t('homepage.registrationForm.placeholders.confirmPassword')}
        value={R.prop('confirmPassword', values)}
        {...{ onChange, onBlur }}
        isError={
          (R.prop('confirmPassword', errors) && R.prop('confirmPassword', touched)) ||
          ((R.prop('password', errors) && R.prop('password', touched)))
        }
        error={
          <>
            <PasswordErrorWrapper marginBottom='0px'>{errors.password}</PasswordErrorWrapper>
            <PasswordErrorWrapper marginBottom='0px'>{errors.confirmPassword}</PasswordErrorWrapper>
          </>
        }
        { ...customizeInput }
      />
    </InputWrapper>


    <PrimaryButtonS
      disabled={isSubmitting}
      type="submit"
    >
      { submitBtnName || i18next.t('homepage.registrationForm.submitBtn')}
    </PrimaryButtonS>
  </Form>
);
