import React from 'react';
import { Trans } from 'react-i18next';
import * as i18n from 'i18next';
import { DebounceInput } from 'react-debounce-input';
import { path } from 'ramda';

import HeaderContainer from './HeaderContainer';

import { routes } from '../../../../routes/routesConfig';
import { IconBtnS, Icon } from '../../../common/';
import { DisplayFlexS } from '../../../../../utils/stylesHelpers';
import IconAdmin from '../../../../assets/imgs/icons/Admin_Icon.png';
import { SearchInput } from '../../../layout/header';
import { COMPANY_STATE } from '../../companies/companiesComponent';

import { ITEMS_PER_PAGE } from '../../../../constants/pagination';

import {
    Container,
    HeaderDropArrow,
    HeaderDropIcon,
    HeaderDropMenu,
    HeaderDropMenuItem,
    HeaderDropMenuText,
    HeaderDropProfile,
    HeaderDropWrap,
    HeaderLeft,
    HeaderNewCompany,
    HeaderRight,
    HeaderSearch,
    HeaderWrap
} from './HeaderS';

const PAGES_WITH_SEARCH = [`${routes.ADMIN.BASE}${routes.ADMIN.COMPANIES}`, `${routes.ADMIN.BASE}${routes.ADMIN.STATUSES}`];

const HeaderComponent = ({ isOpen, setIsOpen, logoutAdmin, fetchCompaniesByAdmin, fetchDeletedCompanies, push, location }) => (
    <HeaderWrap>
        <Container>
            <DisplayFlexS justify="space-between" aItems="center">
                <HeaderLeft justify="space-between" >
                    <HeaderSearch>
                      <DebounceInput
                        minLength={1}
                        debounceTimeout={300}
                        placeholder={i18n.t('header.inputPlaceholder')}
                        onChange={e => {
                          if (path(['state', 'companiesType'], location) === COMPANY_STATE.DELETED) {
                            fetchDeletedCompanies(0, ITEMS_PER_PAGE['10'], null, e.target.value)
                          } else {
                            fetchCompaniesByAdmin(0, ITEMS_PER_PAGE['10'], null, e.target.value, path(['state', 'verified'], location)); // verified on statuses page.
                          }

                          if (!PAGES_WITH_SEARCH.includes(location.pathname)) { // search supported on statuses and companies pages.
                            push(`${routes.ADMIN.BASE}${routes.ADMIN.COMPANIES}`, { search: true });
                          }
                        }}
                        element={SearchInput}
                      />
                    </HeaderSearch>
                    <HeaderNewCompany>
                        <IconBtnS
                            iconName="plus"
                            text={i18n.t('adminPanel.header.addNewCompany')}
                            color="brightViolet"
                            bgColorHover="cornflowerBlueAlpha"
                            displayBlock="flex"
                        />
                    </HeaderNewCompany>
                </HeaderLeft>
                <HeaderRight aItems="center" width="auto">
                    <IconBtnS
                        iconName="user-friends"
                        size="2x"
                        color="violet"
                        title={i18n.t('adminPanel.header.admins')}
                    />
                    <IconBtnS
                        iconName="bell"
                        size="2x"
                        color="greyYellow"
                        margin="0 25px"
                    />
                    <HeaderDropWrap aItems="center">
                        <HeaderDropProfile onClick={() => setIsOpen(!isOpen)}>
                            <HeaderDropIcon aItems="center" justify="center">
                                <Icon src={IconAdmin} alt="admin-icon" size="30px"/>
                            </HeaderDropIcon>
                            <HeaderDropArrow
                                iconName="angle-down"
                                size="2x"
                                color="violet"
                                open={isOpen}
                            />
                        </HeaderDropProfile>
                        {isOpen && (
                            <HeaderDropMenu>
                                <HeaderDropMenuItem to={`${routes.ADMIN.BASE}${routes.ADMIN.PROFILE}`}>
                                    <IconBtnS
                                        iconName="user"
                                        color="violet"
                                        sizeCircle="20px"
                                    />
                                    <HeaderDropMenuText>
                                        <Trans i18nKey="adminPanel.header.myProfile" />
                                    </HeaderDropMenuText>
                                </HeaderDropMenuItem>
                                <HeaderDropMenuItem
                                    to={`${routes.ADMIN.BASE}${routes.ADMIN.LOGIN}`}
                                    onClick={logoutAdmin}
                                >
                                    <IconBtnS
                                        iconName="sign-out-alt"
                                        color="violet"
                                        sizeCircle="20px"
                                    />
                                    <HeaderDropMenuText><Trans i18nKey="adminPanel.header.logout" /></HeaderDropMenuText>
                                </HeaderDropMenuItem>
                            </HeaderDropMenu>
                        )}
                    </HeaderDropWrap>
                </HeaderRight>
            </DisplayFlexS>
        </Container>
    </HeaderWrap>
);

export default HeaderContainer(HeaderComponent);
