import {
  CREATE_CONTACTS_MESSAGE,
  CREATE_WRITE_US_MESSAGE,
  GET_ACTIVE_FILTERS_REQUEST,
  GET_APP_STATISTIC_REQUEST,
  NOTIFICATION_TYPES,
  SHOW_NOTIFICATION_INIT,
  TOGGLE_AUTH_MODAL,
} from './appConstants';

export const toggleModal = ({ open = true, layout }) => ({
  type: TOGGLE_AUTH_MODAL,
  payload: { open, layout },
});

/**
*  @params
*   text - String
* */
export const showSuccessNotification = ({ text }) => ({
  type: SHOW_NOTIFICATION_INIT,
  payload: {
    msgType: NOTIFICATION_TYPES.SUCCESS,
    text,
  }
});

/**
 * @params
 * text - String
* */
export const showErrorNotification = ({ text }) => ({
  type: SHOW_NOTIFICATION_INIT,
  payload: {
    msgType: NOTIFICATION_TYPES.ERROR,
    text
  }
});

/**
 * @params
 * text - String
 * */
export const createContactsMessage = payload => ({
  type: CREATE_CONTACTS_MESSAGE,
  payload
});

export const createWriteUsMessage = payload => ({
  type: CREATE_WRITE_US_MESSAGE,
  payload
});

export const getAppStatistic = () => ({
  type: GET_APP_STATISTIC_REQUEST
});

export const getActiveFilters = () => ({
  type: GET_ACTIVE_FILTERS_REQUEST
});
