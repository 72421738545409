import { all, put, select, takeEvery } from 'redux-saga/effects';
import { uniq } from 'ramda';

import { filterNames } from '../../app/constants/filters';

import {
  APPLY_SELECTS_VALUES_TO_FILTERS,
  MAKE_SEARCH,
  RESET_FILTERS,
  RESET_FILTERS_REQUEST,
  SET_FILTER,
  SET_FILTER_VALUE,
  SET_ZIP_FILTER_VALUE,
  SET_ZIP_FILTER,
  SYNC_SELECTS_WITH_FILTERS
} from './companyFilterConstants';

import { setAllOption, setSearchValue } from './companyFilterActions';
import { clearCompanies, fetchCompanies } from '../company/companyActions';

const syncSelectedFilters = function* () {
  const selectsState = yield select(state => state.companyFilter.selects);

  yield put(clearCompanies());

  yield put({
    type: APPLY_SELECTS_VALUES_TO_FILTERS,
    payload: {
      [filterNames.SERVICES]: selectsState[filterNames.SERVICES] || [],
      [filterNames.KANTONES]: selectsState[filterNames.KANTONES] ? [selectsState[filterNames.KANTONES]] : [],
      //[filterNames.CITY]: selectsState[filterNames.CITY] ? [selectsState[filterNames.CITY]] : [],
      [filterNames.ZIP]: selectsState[filterNames.ZIP] ? [selectsState[filterNames.ZIP]] : [],
    }
  });
};

const searchForCompanies = function*({ payload }) {
  yield put(setSearchValue(payload));
  yield put(fetchCompanies(0, true));
};

const setFilterValue = function*({ payload }) { // { filterName, value }
  const currentFilterState = yield select(state => state.companyFilter.filter[payload.filterName]);
  const activeFilters = yield select(state => state.app.activeFilters[payload.filterName]);
  const { filterName, value } = payload;

  // handle All option case
  if (value === 'Alle' && !currentFilterState.includes('Alle')) {
    yield put(setAllOption({ filterName, values: activeFilters }))
  } else if (currentFilterState.includes('Alle') && value === 'Alle') { // disable all option
    yield put(setAllOption({ filterName, values: [] }));
  } else if (currentFilterState.includes('Alle') && value !== 'Alle') {
    yield put({
      type: SET_FILTER,
      payload: {
        filterName,
        value: 'Alle'
      }
    });

    yield put({
      type: SET_FILTER,
      payload
    });
  } else {
    yield put({
      type: SET_FILTER,
      payload
    });
  }

  if (payload.filterName === filterNames.KANTONES) { // handle change for zip codes (zip co)
    const filtersState = yield select(state => state.companyFilter.filter);
    const locations = yield select(state => state.app.activeFilters[filterNames.LOCATIONS]);

    if (!filtersState[filterNames.KANTONES].includes('Alle')) { // NOTE: in this case currentFilterState it is old state.
      const zipCodesToKeep = locations
        .filter(({ kanton }) => filtersState[filterNames.KANTONES].includes(kanton))
        .map(({ zip }) => zip);

      yield put({
        type: SET_ZIP_FILTER,
        payload: filtersState[filterNames.ZIP].filter(zip => zipCodesToKeep.includes(zip))
      });
    }
  }

  yield put(fetchCompanies(0));
};

const setZipFilterValue = function*({ payload }) {
  const zipFilterState = yield select(state => state.companyFilter.filter[filterNames.ZIP]);
  const locations = yield select(state => state.app.activeFilters[filterNames.LOCATIONS].map(({ zip }) => zip));
  const { value } = payload;

  if (value.includes('**')) { // handle case if it have to be setted like ** and all zips which apply to pattern
    const newValue = zipFilterState.includes(`${value.slice(0, 2)}**`)
      ? [...zipFilterState.filter(zip => !new RegExp(`^${value.slice(0, 2)}`).test(zip))]
      : uniq([
        value,
        ...zipFilterState,
        ...locations.filter(zip => new RegExp(`^${value.slice(0, 2)}`).test(zip))
      ]);

      yield put({
        type: SET_ZIP_FILTER,
        payload: newValue
      });
  } else {
    //const includeOptionWithStars = true; // prop which which set or drop
    const valueGroup = `${value.slice(0, 2)}**`;
    yield put({
      type: SET_ZIP_FILTER,
      payload: zipFilterState.includes(value)
        ? zipFilterState.filter(zip => zip !== value && zip !== valueGroup)
        : [value, ...zipFilterState]
    });
  }

  yield put(fetchCompanies(0));
}

const resetFiltersSaga = function*({ payload }) {
  yield put({
    type: RESET_FILTERS,
  });

  yield put(fetchCompanies(0));
};

export default function* companyFilterSaga() {
  yield all([
    takeEvery(SYNC_SELECTS_WITH_FILTERS, syncSelectedFilters),
    takeEvery(MAKE_SEARCH, searchForCompanies),
    takeEvery(SET_FILTER_VALUE, setFilterValue),
    takeEvery(SET_ZIP_FILTER_VALUE, setZipFilterValue),
    takeEvery(RESET_FILTERS_REQUEST, resetFiltersSaga),
  ]);
}
