import { delay } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { t } from "i18next";

import {
  CREATE_CONTACTS_MESSAGE, CREATE_WRITE_US_MESSAGE,
  GET_ACTIVE_FILTERS_REQUEST,
  GET_ACTIVE_FILTERS_SUCCESS,
  GET_APP_STATISTIC_REQUEST,
  GET_APP_STATISTIC_SUCCESS,
  HIDE_NOTIFICATION,
  NOTIFICATION_DISAPPEAR_TIME,
  SHOW_NOTIFICATION,
  SHOW_NOTIFICATION_INIT
} from './appConstants';
import { generateId } from '../../utils/helpers/getId';
import { showErrorNotification, showSuccessNotification } from "./appActions";
import { get, post, urls } from "../../utils/api";


const showNotification = function* ({ payload }) {
  try {
    const notificationItem = { ...payload, id: generateId() };

    yield put({
      type: SHOW_NOTIFICATION,
      payload: notificationItem, // { type, text, id }
    });

    yield delay(NOTIFICATION_DISAPPEAR_TIME);

    yield put({
      type: HIDE_NOTIFICATION,
      payload: notificationItem.id,
    });

  } catch (e) {
    console.log(e);
  }

};

const createContactsMessageSaga = function* ({ payload }) {
  try {

    yield call(post, urls.contactSupport, payload);

    yield put(showSuccessNotification({text: t('contactsPage.notificationBar.success')}));

  } catch (e) {
    yield put(showErrorNotification({text: t('contactsPage.notificationBar.error')}));
  }

};

const createWriteUsMessageSaga = function* ({ payload }) {
  try {

    yield call(post, urls.writeUs, payload);

    yield put(showSuccessNotification({text: t('modals.writeUs.notificationBar.success')}));
  } catch (e) {
    yield put(showErrorNotification({text: t('modals.writeUs.notificationBar.error')}));
  }

};

const getAppStatisticSaga = function* () {
  try {
    const { data } =  yield call(get, urls.appStatistic);

    yield put({
      type: GET_APP_STATISTIC_SUCCESS,
      payload: data
    })
  } catch (err) {
    console.log(err); // no need to handle this error for app
  }
};

const getActiveFiltersSaga = function* () {
  try {

    const { data } = yield call(get, urls.activeFilters);

    yield put({
      type: GET_ACTIVE_FILTERS_SUCCESS,
      payload: {
        ...data.activeFilters,
        languages: data.activeFilters.languages
    }
    });

  } catch (e) {
    console.log(e);
  }
};

export default function* appSaga() {
  yield all([
    takeEvery(SHOW_NOTIFICATION_INIT, showNotification),
    takeEvery(CREATE_CONTACTS_MESSAGE, createContactsMessageSaga),
    takeEvery(GET_APP_STATISTIC_REQUEST, getAppStatisticSaga),
    takeEvery(GET_ACTIVE_FILTERS_REQUEST, getActiveFiltersSaga),
    takeEvery(CREATE_WRITE_US_MESSAGE, createWriteUsMessageSaga)
  ]);
}
