import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';

import Spinner from '../../assets/imgs/spinner.svg';

import { getThemeColor, getThemeFont, getThemeSize } from '../../../utils/theme';

export const PrimaryButtonWrapS = styled.button`
  outline: none;
  padding: ${R.propOr('15px 65px', 'padding')};
  color: ${getThemeColor(['white'])};
  background-color: ${({ bgColor }) => getThemeColor([ bgColor || 'red'])};
  
  font-family: ${({fontFamily}) => getThemeFont([fontFamily || 'bold'])};
  font-size: ${({ fz }) => getThemeSize([ fz || 'buttonText'])};
  
  border-radius: 3px;
  border:none;
  
  text-transform: ${R.propOr('uppercase', 'transform')};
  transition: all 0.3s ease-in-out;
   
  &:hover {
    cursor: pointer;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, .25);
  }
`;

export const SecondaryBtnS = styled(PrimaryButtonWrapS)`
  color: ${getThemeColor(['violet'])};
  background-color: ${getThemeColor(['white'])};
  box-shadow: 1px 1px 2px rgba(0, 0, 0, .25);
`;

export const LightBtnS = styled(PrimaryButtonWrapS)`
  color: ${({ color }) => getThemeColor([color || 'red'])};
  font-family: ${getThemeFont(['regular'])};
  background-color: ${getThemeColor(['white'])};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  min-width: 120px;
  &:hover {
    color: ${getThemeColor(['white'])};
    background-color: ${getThemeColor(['red'])};
  }
`;

export const GreyBtnS = styled(PrimaryButtonWrapS)`
  color: ${getThemeColor(['white'])};
  background-color: ${getThemeColor(['greyDark'])};
  min-width: 120px;
`;

export const MobileRoundedBtnS = styled.button`
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 50%;
  box-shadow: 0 1px 3px ${getThemeColor(['primaryGray'])};
  background-color: ${({ bgColor }) => getThemeColor([ bgColor || null])};
`;

export const PrimaryButtonS = ({ children, loading, ...props }) => (
  <PrimaryButtonWrapS { ...props }>
    {
      loading ? (<img src={Spinner} alt="spinner" />) : children
    }
  </PrimaryButtonWrapS>
);

