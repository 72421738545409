import {
  ADD_COMPANY_VISIT,
  CLEAR_COMPANY_LIST, FETCH_COMPANIES_BY_ADMIN,
  FETCH_COMPANIES_REQUEST,
  FETCH_COMPANY_ITEM_REQUEST,
  LAST_CREATES_COMPANY_REQUEST,
  REMOVE_COMPANY_LOGO_REQUEST,
  UPDATE_COMPANY_LOGO_REQUEST,
  UPDATE_COMPANY_REQUEST,
  FETCH_DELETED_COMPANIES_REQUEST,
  UPDATE_COMPANY_STATUS_REQUEST,
  DELETE_COMPANY_REQUEST, UPDATE_VERIFIED_COMPANY_REQUEST
} from './companyConstants';

export const fetchCompanyLastCreated = ({ limit, skip }) => ({
  type: LAST_CREATES_COMPANY_REQUEST,
  payload: {
    limit,
    skip
  }
});

/*
* makeSearch - this prop is added cause we need to ignore filters when user
*              make search but meanwhile once use start filtering results keep search active
* */
export const fetchCompanies = (page, makeSearch = false) => ({
  type: FETCH_COMPANIES_REQUEST,
  payload: { page, makeSearch }
});

// Only for Admins
export const fetchCompaniesByAdmin = (page, itemsPerPage, sort, search, verified) => ({
  type: FETCH_COMPANIES_BY_ADMIN,
  payload: { page, itemsPerPage, sort, verified, search }
});

// Only for Admins
export const fetchDeletedCompanies = (page, itemsPerPage, sort, search) => ({
  type: FETCH_DELETED_COMPANIES_REQUEST,
  payload: { page, itemsPerPage, sort, search }
});

export const updateVerifiedCompany = (companyId, verified) => ({
  type: UPDATE_VERIFIED_COMPANY_REQUEST,
  payload: { companyId, verified }
});

export const fetchCompanyItem = id => ({
  type: FETCH_COMPANY_ITEM_REQUEST,
  payload: id,
});

export const updateCompany = (payload, isAdmin) => ({
  type: UPDATE_COMPANY_REQUEST,
  payload,
  isAdmin
});

export const updateCompanyLogo = (payload) => ({
  type: UPDATE_COMPANY_LOGO_REQUEST,
  payload
});

export const removeLogo = (payload, isAdmin) => ({
  type: REMOVE_COMPANY_LOGO_REQUEST,
  payload,
  isAdmin
});

export const addVisitsForCompany = id => ({
  type: ADD_COMPANY_VISIT,
  payload: id,
});

export const updateCompanyStatus = (status, companyId) => ({
  type: UPDATE_COMPANY_STATUS_REQUEST,
  payload: { status, companyId }
});

export const removeCompanyFully = companyId => ({
  type: DELETE_COMPANY_REQUEST,
  payload: { companyId }
});

export const clearCompanies = () => ({
  type: CLEAR_COMPANY_LIST
});
