import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import i18n from 'i18next';
import { compose, lifecycle, withPropsOnChange, withState } from 'recompose';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { DebounceInput } from 'react-debounce-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {getScreenSize, getThemeColor, getThemeFont} from '../../../utils/theme';
import { DisplayFlexS } from '../../../utils/stylesHelpers/DispalyFlexS';
import LanguageSwitcher from '../languageSwitcher';
import { modalGA, socialLinkGA } from '../../../utils/GA';

import { AuthModal } from '../modals';
import { authModalLayouts } from '../../constants/modals';

import { routes } from '../../routes/routesConfig';

import { Input, LinkS, LinkTextS, Logo, PrimaryButtonS } from '../common';

import { appActions } from '../../../modules/app';
import { authActions } from '../../../modules/auth';
import { companyFilterActions } from '../../../modules/companyFilter';

import { isLoggedIn } from '../../../utils/auth';
import DropdownProfileMenu from "../dropdownProfileMenu";
import withWindowSize from "react-window-size";
import { withLanguageOnChange } from "../../../utils/enhancers/withLanguageChange";
import { socialGA } from "../../constants/googleAnalytics";

const { toggleModal } = appActions;
const { logout } = authActions;
const { makeSearch } = companyFilterActions;

const menuItems = [
  {
    transKey: 'header.menu.blog',
    redirect: 'https://treuhand-suche.ch/blog/',  // go at another site or blog
    onClick: socialLinkGA(socialGA.BLOG, 'https://treuhand-suche.ch/blog/')
  },
  {
    transKey: 'header.menu.forCompanies',
    redirect: `${routes.FOR_COMPANIES}`,
  },
  {
    transKey: 'header.menu.loginForCompanies',
    onClick: toggleModal => !isLoggedIn() && toggleModal({ open: true, layout: authModalLayouts.login }) && modalGA('/login'),
    button: true, // indicates diff style of btn
    variableComponent: true
  },
];

const HeaderWrapperS = styled.div`
  padding: 14.5px;
  
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  
  width: 1200px
  max-width: ${getScreenSize(['tabletPC'])};
  margin: 0 auto;
  
  box-shadow: 0 2px 3px rgba(0, 0, 0, .25);
  
  background-color: ${getThemeColor(['white'])};
  z-index: 999;
  
  border-radius: 3px;
  
  @media (max-width: ${getScreenSize(['tabletPC'])}) {
      width: 100%;
   }
`;

const SearchInputWrapS = styled.div`
  position: relative;
  
  display: flex;
  
  svg {
    height: 1.5em!important;
    width: 1.5em!important;
    
    position: absolute;
    right: 8px;
    top: 18%;
  }
  
   @media (max-width: ${getScreenSize(['medium'])}) {
      svg {
        display: ${ ({ mobileInput }) => mobileInput ? 'block': 'none' };
      }
   }
`;

const SearchInputS = styled(Input)`
  width: 100%;
  padding: 7px 35px 7px 20px;
  font-family: ${getThemeFont(['bold'])};
  
  @media (max-width: ${getScreenSize(['medium'])}) {
    display: none;
  }
`;

const LogoHeaderS = styled(Logo)`
`;

const MenuS = styled.ul`
   display: flex;
   align-items: center;
   
   line-height: ${R.propOr('30px', 'lineHeight')};
   
   @media (max-width: ${getScreenSize(['medium'])}) {
    display: none;
   }
`;

const PrimaryButtonStyle  = styled(PrimaryButtonS)`
  box-shadow: 1px 1px 2px rgba(0, 0, 0, .25);
  
  &:hover {
    box-shadow: 1px 1px 2px rgba(0, 0, 0, .25);
    background-color: ${getThemeColor(['red'])};
  }
`;

const BurgerIconS = styled.i` // this just in UX design will change it when UI will be ready with icons
  display: none;
  font-weight: bold;
  float: left;
  
  &:hover{
    background-color: red;
  }
   
  @media (max-width: ${getScreenSize(['medium'])}) {
    display: inline-block;
  }
`;

const MobileMenuS = styled.div`
  display: ${({ isOpen }) => isOpen ? 'block': 'none'};
  
  margin-top: 20px;
  height: 100%;
  width: 100%;
   
  background-color: ${getThemeColor(['white'])};
  
  @media (max-width: ${getScreenSize(['medium'])}) {
    ul, input {
      display: block;
    }
    
    & > div:first-child {
      margin: 0 auto;
      width: 90%;
    }
    
    input {
      width: 100%;
    }

    li {
      border-bottom: 1px solid ${getThemeColor(['grayBlue'])}
      padding: 10px 0;
    }
   }
`;

const Redirect = styled.a`
  margin: 0 26px 0 0;

  @media (max-width: ${getScreenSize(['medium'])}) {
    margin: 20px;
  }
`;

export const SearchInput = props => (
  <SearchInputWrapS {...props}>
    <SearchInputS {...props} />
    <FontAwesomeIcon icon="search" onClick={ () => {
      if (props.mobileInput) {
        props.push(routes.COMPANIES);
        props.toggleMobileMenu(!props.isMobileMenuOpen)
      }
    }}/>
  </SearchInputWrapS>
);

const Header = ({
  isMobileMenuOpen,
  toggleMobileMenu,
  toggleModal,
  push,
  makeSearch,
  company,
  showDropMenu,
  setDropMenu,
  logout,
  location,
  refDropDownMenu,
  widthDropDownMenu,
  windowWidth,
  searchInput,
}) => (
    <HeaderWrapperS isOpen={isMobileMenuOpen} >

    <AuthModal />

    <DisplayFlexS justify="space-between" aItems='center' alignItemsScreenMedium='center'>
      <BurgerIconS onClick={() => toggleMobileMenu(!isMobileMenuOpen)} >
        <FontAwesomeIcon icon="bars" />
      </BurgerIconS>
      <DebounceInput
        minLength={1}
        debounceTimeout={300}
        value={searchInput}
        placeholder={i18n.t('header.inputPlaceholder')}
        onChange={e => {
          makeSearch(e.target.value);

          if (location.pathname !== routes.COMPANIES) {
            push(routes.COMPANIES);
          }
        }}
        element={SearchInput}
      />

      {
        windowWidth > 768 ? (<LogoHeaderS />) : (
          <DisplayFlexS justifyScreenMedium='center'>
            <LogoHeaderS />
          </DisplayFlexS>
        )
      }
      <MenuS>
        <LanguageSwitcher/>
        {
          /* TODO: rewrite it. It so complicated and too much conditions in it */
          menuItems.map(({ transKey, linkTo, onClick, button, variableComponent, redirect }) => (
            <li key={transKey}>
              {
                !!company && variableComponent ? (
                  <DropdownProfileMenu
                    refDropDownMenu={refDropDownMenu}
                    widthDropDownMenu={widthDropDownMenu}
                    company={company}
                    showDropMenu={showDropMenu}
                    setDropMenu={setDropMenu}
                    logout={logout}
                    location={location}
                  />
                ) : (
                  button ? (
                    !company ? (
                        <PrimaryButtonStyle
                          bgColor="violet"
                          padding="7px 17px"
                          transform="capitalize"
                          fz="normalText"
                          onClick={() => onClick(toggleModal)}
                        >
                          {i18n.t(transKey)}
                        </PrimaryButtonStyle>
                    ): null
                  ) : (
                    redirect ? (
                      <Redirect href={redirect} style={{ textDecoration: 'none' }}>
                        <LinkTextS onClick={() => onClick ? onClick() : null}>
                          {i18n.t(transKey)}
                        </LinkTextS>
                      </Redirect>
                      ) : (
                      <LinkS
                        onClick={() => onClick && onClick(toggleModal)}
                        to={linkTo || location.pathname}
                        margin='0 26px 0 0'
                      >
                        <LinkTextS>
                          {i18n.t(transKey)}
                        </LinkTextS>
                      </LinkS>
                    )
                  )
                )
              }
            </li>
          ))
        }
      </MenuS>

    </DisplayFlexS>

    <MobileMenuS isOpen={isMobileMenuOpen}>
      <SearchInput
        placeholder={i18n.t('header.inputPlaceholder')}
        onChange={e => makeSearch(e.target.value)}
        push={push}
        toggleMobileMenu={toggleMobileMenu}
        isMobileMenuOpen={toggleMobileMenu}
        mobileInput
      />
      <LanguageSwitcher/>
      <MenuS>
        {
          menuItems.map(({ transKey, linkTo, onClick, variableComponent, redirect }) => (
            <li key={transKey}>
              {
                !!company && variableComponent ? (
                    <DropdownProfileMenu
                      company={company}
                      showDropMenu={showDropMenu}
                      setDropMenu={setDropMenu}
                      logout={logout}
                      location={location}
                      toggleMobileMenu={toggleMobileMenu}
                      isMobileMenuOpen={isMobileMenuOpen}
                      mobileVersion
                    />
                ) : (
                  redirect ? (
                    <Redirect href={redirect} onClick={ () => toggleMobileMenu(!isMobileMenuOpen) }>
                      <LinkTextS onClick={() => onClick ? onClick() : null}>
                        {i18n.t(transKey)}
                      </LinkTextS>
                    </Redirect>
                  ) : (
                    <LinkS margin="20px" onClick={() => {onClick && onClick(toggleModal); toggleMobileMenu(!isMobileMenuOpen)}} to={linkTo || '/'}>
                      <LinkTextS>
                        {i18n.t(transKey)}
                      </LinkTextS>
                    </LinkS>
                  )
                )
              }
            </li>
          ))
        }
      </MenuS>
    </MobileMenuS>
  </HeaderWrapperS>
);

export default compose(
  connect(({ app, auth, router, companyFilter }) => ({
    authModal: app.authModal,
    company: auth.activeUser,
    location: router.location,
    searchInput: companyFilter.search,
    companyFilter: companyFilter
  }), { toggleModal, push, logout, makeSearch }),
  withWindowSize,
  withLanguageOnChange,
  withState('isMobileMenuOpen', 'toggleMobileMenu', false),
  withState('showDropMenu', 'setDropMenu', false),
  withState('widthDropDownMenu', 'setWidthDropDownMenu', 0),
  withPropsOnChange(['company'], () => {
    return {
      refDropDownMenu: React.createRef()
  };
  }),
  lifecycle({
    componentDidUpdate(prevProps){
      if (prevProps.refDropDownMenu.current !== this.props.refDropDownMenu.current) {
        this.props.setWidthDropDownMenu(this.props.refDropDownMenu.current.offsetWidth);
      }

      if (prevProps.location.pathname !== this.props.location.pathname){
        this.props.toggleModal({ open: false, layout: null });
      }

      if (prevProps.location.pathname === routes.COMPANIES && prevProps.location.pathname !== this.props.location.pathname){
        this.props.makeSearch('');
      }
    },
  })
)(Header);
