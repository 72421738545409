import NotFound from '../components/notFound';
import Loadable from 'react-loadable';

const routesComponents = {
  HOME: Loadable({
    loader: () => import(/* webpackChunkName: "homepage" */ '../components/homepage'),
    loading: () => null,
    modules: ['homepage']
  }),
  COMPANIES: Loadable({
    loader: () => import(/* webpackChunkName: "companiesList" */ '../components/companiesList'),
    loading: () => null,
    modules: ['companies']
  }),
  COMPANY: Loadable({
    loader: () => import(/* webpackChunkName: "company" */ '../components/company'),
    loading: () => null,
    modules: ['company']
  }),
  COMPANY_EDIT: Loadable({
    loader: () => import(/* webpackChunkName: "companyEdit" */ '../components/companyEdit'),
    loading: () => null,
    modules: ['companyEdit']
  }),
  FOR_COMPANIES: Loadable({
    loader: () => import(/* webpackChunkName: "for-companies" */ '../components/forCompanies'),
    loading: () => null,
  }),
  REVIEW_CREATE: Loadable({
    loader: () => import(/* webpackChunkName: "reviewCreate" */ '../components/reviewCreate'),
    loading: () => null,
    modules: ['reviewCreate']
  }),
  PROFILE: Loadable({
    loader: () => import(/* webpackChunkName: "reviewCreate" */ '../components/profile'),
    loading: () => null,
    modules: ['profile']
  }),
  CONTACTS: Loadable({
    loader: () => import(/* webpackChunkName: "reviewCreate" */ '../components/contacts'),
    loading: () => null,
  }),
  AGB: Loadable({
    loader: () => import(/* webpackChunkName: "legalPages" */ '../components/legalPages/agbComponent'),
    loading: () => null
  }),
  DATA_PROTECTION: Loadable({
    loader: () => import(/* webpackChunkName: "legalPages" */ '../components/legalPages/dataProtectionComponent'),
    loading: () => null
  }),
  ADMIN: {
    LOGIN: Loadable({
      loader: () => import(/* webpackChunkName: "legalPages" */ '../components/adminPanel/adminLogin'),
      loading: () => null
    }),
    DASHBOARD:  Loadable({
      loader: () => import(/* webpackChunkName: "legalPages" */ '../components/adminPanel/dashboard'),
      loading: () => null
    }),
    PROFILE:  Loadable({
      loader: () => import(/* webpackChunkName: "legalPages" */ '../components/adminPanel/adminProfile'),
      loading: () => null
    }),
    COMPANIES:  Loadable({
      loader: () => import(/* webpackChunkName: "legalPages" */ '../components/adminPanel/companies'),
      loading: () => null
    }),
    STATUSES:  Loadable({
      loader: () => import(/* webpackChunkName: "legalPages" */ '../components/adminPanel/companyStatuses/index'),
      loading: () => null
    }),
    COMPANY_PROFILE:  Loadable({
      loader: () => import(/* webpackChunkName: "legalPages" */ '../components/companyEdit'),
      loading: () => null
    }),
    COMMENTS:  Loadable({
      loader: () => import(/* webpackChunkName: "legalPages" */ '../components/adminPanel/adminManagementComments/index'),
      loading: () => null
    }),
  },
  NOT_FOUND: NotFound
};

const routes = {
  HOME: '/',
  COMPANIES: '/companies',
  COMPANY: `/companies/:id`,
  COMPANY_EDIT: `/companies/:id/edit`,
  FOR_COMPANIES: `/for-companies`,
  REVIEW_CREATE: `/companies/:id/review`,
  PROFILE: `/profile`,
  CONTACTS: `/contacts`,
  AGB: '/agb',
  DATA_PROTECTION: '/data-protection',
  ADMIN: {
    BASE: '/admin',
    LOGIN: '/login',
    STATUSES: '/statuses',
    PROFILE: '/profile',
    COMPANIES: '/companies',
    COMPANY_PROFILE: '/company-profile/:id',
    COMMENTS: '/comments',
    NEWSLETTER: '/newsletter'

  }
};

export { routesComponents, routes }
