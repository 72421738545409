import React, { Fragment } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import { authModalLayouts } from '../../../constants/modals';
import { LinkS, SecondaryBtnS, Text } from '../../common';
import RegistrationForm from '../../forms/RegistrationForm';
import LoginForm from '../../forms/LoginForm';
import EmailForm from '../../forms/common/emailForm';
import { getThemeColor, getThemeSize } from '../../../../utils/theme';
import { modalGA } from '../../../../utils/GA';
import { routes } from '../../../routes/routesConfig';

//const registrationTabs = {
//  user: {
//    index: 0,
//    text: 'modals.auth.registration.userTabName'
//  },
//  company: {
//    index: 1,
//    text: 'modals.auth.registration.companyTabName'
//  }
//};

const TextBtnS = styled(Text)`
  border-bottom: ${({ isActive }) => isActive ? '1px solid black' : 'none'};
  color: ${getThemeColor(['greyViolet'])};
  
  &:hover {
    cursor: pointer;
    font-weight: bold;
  }
`;

const GrayPlaceholderS = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${getThemeColor(['brightVioletLight'])};
  padding: 30px;
  width: 117%;
  margin-bottom: -30px; // padding for parent
`;

const AuthHeaderTextS = styled.h2`
  font-size: ${getThemeSize(['headers', 'authModal'])};
  color: ${getThemeColor(['darkerViolet'])};
  margin-bottom: 30px;
`;


const AgreeTextForRegistration = styled(Text)`
  margin: 22px 0 25px;
  color: ${getThemeColor(['greyViolet'])};
  font-size: ${getThemeSize(['tagSize'])};
  max-width: 80%;
`;

const RegistrationLayout = ({ setModalStateTo }) => (
  <Fragment>
      <AuthHeaderTextS style={{ marginBottom: 25 }}>
        <Trans i18nKey="modals.auth.registration.header" />
      </AuthHeaderTextS>

      {/* Temporary disabled in scope of https://gitlab.com/yaryk/treuhandsuche-frontend/issues/17 #2 point */}
      {/*<DisplayFlexS >*/}
      {/*    {*/}
      {/*      Object.keys(registrationTabs).map(key => (*/}
      {/*        <TabsButton*/}
      {/*          selected={activeTab.index === registrationTabs[key].index}*/}
      {/*          key={registrationTabs[key].index} onClick={() => setActiveTab(registrationTabs[key])}*/}
      {/*        >*/}
      {/*          <Trans i18nKey={registrationTabs[key].text} />*/}
      {/*        </TabsButton>*/}

      {/*      ))*/}
      {/*    }*/}
      {/*</DisplayFlexS>*/}

      {/*{*/}
      {/*  activeTab === registrationTabs.user ?*/}
      {/*    (<RegistrationUserFormContainer />) :*/}
      {/*    (<RegistrationForm />)*/}
      {/*}*/}
      <RegistrationForm />

      <AgreeTextForRegistration>
        <Trans i18nKey="modals.auth.registration.bottomCapture.text" />
        <LinkS to={routes.AGB}><Trans i18nKey="modals.auth.registration.bottomCapture.privacyBtn" /></LinkS>
        <Trans i18nKey="modals.auth.registration.bottomCapture.and" />
        <LinkS to={routes.DATA_PROTECTION}><Trans i18nKey="modals.auth.registration.bottomCapture.termsBtn" /></LinkS>

      </AgreeTextForRegistration>

      <GrayPlaceholderS>
        <AuthHeaderTextS>
          <Trans i18nKey="modals.auth.registration.backToLoginMsg" />
        </AuthHeaderTextS>
        <SecondaryBtnS onClick={() => setModalStateTo({ layout: authModalLayouts.login }) && modalGA('/login')}>
          <Trans i18nKey="modals.auth.registration.singInBtn" />
        </SecondaryBtnS>
      </GrayPlaceholderS>
  </Fragment>
);

const LoginLayout = ({ setModalStateTo }) => (
  <Fragment>
    <AuthHeaderTextS>
      <Trans i18nKey="modals.auth.login.header" />
    </AuthHeaderTextS>

    <LoginForm />

    <TextBtnS style={{ marginBottom: '25px' }} onClick={() => setModalStateTo({ layout: authModalLayouts.resetPass })}>
      <Trans i18nKey="modals.auth.login.forgotPass" />
    </TextBtnS>

    <GrayPlaceholderS>
      <AuthHeaderTextS>
        <Trans i18nKey="modals.auth.login.noSignedMsg" />
      </AuthHeaderTextS>
      <SecondaryBtnS onClick={() => setModalStateTo({ layout: authModalLayouts.registration }) && modalGA('/registration')}>
        <Trans i18nKey="modals.auth.login.singUpBtn" />
      </SecondaryBtnS>
    </GrayPlaceholderS>

  </Fragment>
);

const ResetPass = ({ resetPass, auth }) => (
  <Fragment>
    <AuthHeaderTextS>
        <Trans i18nKey="modals.auth.resetPass.header" />
    </AuthHeaderTextS>
    <Text>
      <Trans i18nKey="modals.auth.resetPass.capture" />
    </Text>

    <EmailForm submitHandler={resetPass} serverError={auth.resetError} />
  </Fragment>
);

const SendEmail = ({ auth, sendEmail }) => (
  <Fragment>
    <AuthHeaderTextS>
      <Trans i18nKey="modals.auth.sendMail.header" />
    </AuthHeaderTextS>

    <Text> <Trans i18nKey="modals.auth.sendMail.capture" /> </Text>

    <EmailForm submitHandler={sendEmail} serverError={auth.emailError} />
  </Fragment>
);

export {
  RegistrationLayout,
  LoginLayout,
  ResetPass,
  SendEmail
}
