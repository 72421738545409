import React, { Fragment } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';

import { DisplayFlexS } from '../../../utils/stylesHelpers';
import { getThemeColor, getThemeSize } from '../../../utils/theme';

import { FieldError } from './Error';
import { InputLabel } from './TextInput';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const SelectFieldS = styled.select`
  width: ${R.propOr('100%', 'width')};
  padding: 8px 20px;
  height: ${R.propOr('50px', 'height')};
  
  flex-basis: ${({ closeBtnHandler }) => closeBtnHandler ? '95%' : '100%'};
  
  font-size: ${getThemeSize(['bigText'])};
  color: ${getThemeColor(['darkerViolet'])};
  
  border: .5px solid ${getThemeColor(['greyLight'])};
  border-radius: 2px;
  
  box-shadow: 1px 0 1px rgba(0, 0, 0, .25);
  
  &:hover {
    cursor: pointer;
  }
`;


const DeleteIcon = styled(FontAwesomeIcon)`
  margin-left: 5px;
  color: ${getThemeColor(['red'])};
  
  &:hover {
    cursor: pointer;
  }
`;


export const SelectS = ({ labelColor, label, error, name, isError, closeBtnHandler, width, ...rest }) => (
  <Fragment>
    {
      label ? (<InputLabel labelColor={labelColor} htmlFor={name}>{label}</InputLabel>) : null
    }
    <DisplayFlexS justify="space-between" alignItemsScreenMedium="center" aItems="center" basis={width} >
      <SelectFieldS {...{ name, isError, closeBtnHandler }} {...rest} />
      {
        closeBtnHandler ?  <DeleteIcon onClick={closeBtnHandler} icon='times'/> : null
      }
    </DisplayFlexS>
    {(isError && error) ? <FieldError {...{ error }} /> : null}
  </Fragment>
);
