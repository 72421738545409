import {all, call, put, select, takeEvery } from 'redux-saga/effects';
import {complement, defaultTo, equals, filter, identity, isEmpty, omit} from 'ramda';
import {t} from 'i18next';

import {companySorts} from '../../app/constants/sorts';
import {ITEMS_PER_PAGE} from '../../app/constants/pagination';

import buildUrlWithQueries from '../../utils/helpers/buildUrlWithQuery';

import {
  ADD_COMPANY_VISIT,
  FETCH_COMPANIES_ADD_SUCCESS,
  FETCH_COMPANIES_BY_ADMIN,
  FETCH_DELETED_COMPANIES_REQUEST,
  FETCH_COMPANIES_ERROR,
  FETCH_COMPANIES_INIT_SUCCESS,
  FETCH_COMPANIES_REQUEST,
  FETCH_COMPANY_ITEM_ERROR,
  FETCH_COMPANY_ITEM_REQUEST,
  FETCH_COMPANY_ITEM_SUCCESS,
  LAST_CREATES_COMPANY_ERROR,
  LAST_CREATES_COMPANY_REQUEST,
  LAST_CREATES_COMPANY_SUCCESS,
  REMOVE_COMPANY_LOGO_ERROR,
  REMOVE_COMPANY_LOGO_REQUEST,
  REMOVE_COMPANY_LOGO_SUCCESS,
  UPDATE_COMPANY_ERROR,
  UPDATE_COMPANY_LOGO_ERROR,
  UPDATE_COMPANY_LOGO_REQUEST,
  UPDATE_COMPANY_LOGO_SUCCESS,
  UPDATE_COMPANY_REQUEST,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_STATUS_REQUEST,
  UPDATE_COMPANY_STATUS_ERROR,
  COMPANY_STATUS_INACTIVE_SUCCESS,
  COMPANY_STATUS_ACTIVE_SUCCESS,
  DELETE_COMPANY_REQUEST,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_ERROR, UPDATE_VERIFIED_COMPANY_REQUEST, UPDATE_VERIFIED_COMPANY_ERROR, UPDATE_VERIFIED_COMPANY_SUCCESS
} from './companyConstants';
import {showErrorNotification, showSuccessNotification} from '../app/appActions';

import {get, patch, post, remove, urls} from '../../utils/api';
import {UPDATE_ACTIVE_USER_COMPANY_SUCCESS, UPDATE_AVATAR_ACTIVE_USER_SUCCESS} from "../auth/authConstants";
import { MODAL_TYPES } from "../modals/modalsConstants";

export const fetchLastCreatedSaga = function* ({payload}) {
    try {
        const {data} = yield call(get, buildUrlWithQueries(urls.lastCreatedCompanies, payload));

        yield put({
            type: LAST_CREATES_COMPANY_SUCCESS,
            payload: data
        });

    } catch (err) {
        yield put({
            type: LAST_CREATES_COMPANY_ERROR,
            error: err.data.error
        });
    }
};

export const fetchCompaniesSaga = function* ({payload: {page, makeSearch}}) {
    try {
        // take filters and pagination data from redux store
        const sortSelector = state => state.companyFilter.sort;
        const filterSelector = state => state.companyFilter.filter;
        const searchSelector = state => state.companyFilter.search;

        const sort = yield select(sortSelector);
        const filters = yield select(filterSelector);
        const search = yield select(searchSelector);
        const loadedCompanies = yield select(({company}) => company.list);

        const pageOrCalculated = defaultTo(Math.ceil(loadedCompanies.length / ITEMS_PER_PAGE[20]));

        const isUserSearchRequest = makeSearch && !!search.length;

        const query = {
            skip: pageOrCalculated(page) * ITEMS_PER_PAGE[20],
            limit: ITEMS_PER_PAGE[20],
            sort,
            ...(isUserSearchRequest ? {} : {...filter(complement(isEmpty), filters)})
        };

        if (search) {
            query.search = search;
        }

        const {data} = yield call(get, buildUrlWithQueries(urls.companiesList,
            equals(sort, companySorts.POPULAR.value) ?
                omit(['sort'], query) :
                identity(query)));

        yield put({
          type: pageOrCalculated(page) * ITEMS_PER_PAGE[20] === 0 ? FETCH_COMPANIES_INIT_SUCCESS : FETCH_COMPANIES_ADD_SUCCESS, // handle pagination
          payload: data, // { count, entities, isAllEntitiesFromKanton }
        })
    } catch (err) {
        yield put({
            type: FETCH_COMPANIES_ERROR,
            error: err.data.error
        });
    }
};

export const fetchCompanyItemSaga = function* ({payload}) {
    try {

        const {data} = yield  call(get, urls.companyItem(payload));

        yield put({
            type: FETCH_COMPANY_ITEM_SUCCESS,
            payload: data
        })

    } catch (err) {
        yield put({
            type: FETCH_COMPANY_ITEM_ERROR,
            error: err.data.error
        })
    }
};

const updateCompany = function* ({ payload, isAdmin }) {
    try {
        const {data} = yield call(patch, urls.updateCompany(payload._id), payload);

        yield put({
            type: UPDATE_COMPANY_SUCCESS,
            payload: data,
        });

        // update activeUser at authReducer in case user edit company from client
        // TODO: rewrite storing of active user in to two separate properties.
        if (!isAdmin) {
          yield put({
            type: UPDATE_ACTIVE_USER_COMPANY_SUCCESS,
            payload: data,
          });
        }

        yield put(showSuccessNotification({text: t('companyEdit.notificationBar.success')}));

    } catch (err) {
        yield put({
            type: UPDATE_COMPANY_ERROR,
            error: err.data
        });
        yield put(showErrorNotification({text: t('companyEdit.notificationBar.error')}));
    }
};

export const updateCompanyLogoSaga = function* ({payload: {_id, formData}}) {
    try {
        const {data} = yield call(post, urls.updateCompanyLogo(_id), formData);

        yield put({
            type: UPDATE_COMPANY_LOGO_SUCCESS,
            payload: data,
        });

        // update activeUser.logo at authReducer
        yield put({
            type: UPDATE_AVATAR_ACTIVE_USER_SUCCESS,
            payload: data.logo,
        });
    } catch (e) {
        yield put({
            type: UPDATE_COMPANY_LOGO_ERROR,
            error: e.data
        })
    }
};

export const removeCompanyLogoSaga = function* ({ payload, isAdmin }) {
    try {
        yield call(remove, urls.deleteCompanyLogo(payload));
        //You make a call in order to get data without a logo

        const { data } = yield call(get, urls.companyItem(payload));

        yield put({
            type: REMOVE_COMPANY_LOGO_SUCCESS,
        });

        // remove activeUser.logo at authReducer
        if (!isAdmin) {
          yield put({
            type: UPDATE_ACTIVE_USER_COMPANY_SUCCESS,
            payload: data,
          });
        }
    } catch (e) {
        yield put({
            type: REMOVE_COMPANY_LOGO_ERROR,
            error: e.data
        })
    }
};

export const addCompanyVisit = function* ({payload}) {
    try {
        yield call(post, urls.addCompanyVisit(payload));
    } catch (err) {
        console.log(err);
    }
};

const fetchCompaniesByAdminSaga = (url) => function* ({ payload: { page, itemsPerPage, sort, search, verified } }) {
    try {
      const loadedCompanies = yield select(({company}) => company.list);

      const query = {
          skip: page * loadedCompanies.length,
          limit: itemsPerPage,
      };

      if (sort) {
        query.sort = sort;
      }
      if (search) {
        query.search = search;
      }

      if (verified) {
        query.verified = verified
      }

      const { data } = yield call(get, buildUrlWithQueries(url, query));

      yield put({
          type: FETCH_COMPANIES_INIT_SUCCESS,
          payload: data, // { count, entities }
      })
    } catch (err) {
        yield put({
            type: FETCH_COMPANIES_ERROR,
            error: err.data.error
        });
    }
};

const updateCompanyStatusSaga = function* ({ payload: { status, companyId } }) {
  try {
    yield call(patch, urls.setCompanyStatus(companyId), { status });

    yield put({
      type: status === 'active' ? COMPANY_STATUS_ACTIVE_SUCCESS : COMPANY_STATUS_INACTIVE_SUCCESS,
      payload: { companyId },
    })
  } catch (err) {
    yield put({
      type: UPDATE_COMPANY_STATUS_ERROR,
      error: err.data.error
    });
  }
};

const deleteCompanySaga = function* ({ payload: { companyId, status } }) {
  try {

    if (!companyId) {
      companyId = yield select(({ modals }) => modals[MODAL_TYPES.COMPANY_DELETE_CONFIRMATION].companyId);
    }

    yield call(remove, urls.companyItem(companyId));

    yield put({
      type: DELETE_COMPANY_SUCCESS,
      payload: { companyId },
    })
  } catch (err) {
    yield put({
      type: DELETE_COMPANY_ERROR,
      error: err.data.error
    });
  }
};

const updateVerifiedCompanySaga = function* ({ payload: { companyId, verified } }) {
  try {
    const { data } = yield call(post, urls.updateVerifiedCompany(companyId), { verified });
    yield put({
      type: UPDATE_VERIFIED_COMPANY_SUCCESS,
      payload: data
    })
  } catch (err) {
    yield put({
      type: UPDATE_VERIFIED_COMPANY_ERROR,
      error: err.data.error
    });
  }
};

export default function* companySaga() {
  yield all([
    takeEvery(LAST_CREATES_COMPANY_REQUEST, fetchLastCreatedSaga),
    takeEvery(FETCH_COMPANIES_REQUEST, fetchCompaniesSaga),
    takeEvery(FETCH_COMPANY_ITEM_REQUEST, fetchCompanyItemSaga),
    takeEvery(UPDATE_COMPANY_REQUEST, updateCompany),
    takeEvery(UPDATE_COMPANY_LOGO_REQUEST, updateCompanyLogoSaga),
    takeEvery(REMOVE_COMPANY_LOGO_REQUEST, removeCompanyLogoSaga),
    takeEvery(ADD_COMPANY_VISIT, addCompanyVisit),
    takeEvery(FETCH_COMPANIES_BY_ADMIN, fetchCompaniesByAdminSaga(urls.companiesList)),
    takeEvery(FETCH_DELETED_COMPANIES_REQUEST, fetchCompaniesByAdminSaga(urls.deletedCompanies)),
    takeEvery(UPDATE_COMPANY_STATUS_REQUEST, updateCompanyStatusSaga),
    takeEvery(DELETE_COMPANY_REQUEST, deleteCompanySaga),
    takeEvery(UPDATE_VERIFIED_COMPANY_REQUEST, updateVerifiedCompanySaga),
  ]);
}
