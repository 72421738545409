import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import * as i18n from 'i18next';

import ConfirmationModal from '../../modals/confirmation';
import { IconBtnS, Pagination, SelectItemsPerPage, Table } from '../../common';

import { ITEMS_PER_PAGE } from '../../../constants/pagination';

import { modalsActions, modalsConstants } from '../../../../modules/modals';
import { companyActions } from '../../../../modules/company';
import { MODAL_TYPES } from '../../../../modules/modals/modalsConstants';

import {
  NavTabs,
  NavTabsBtn,
  NavWrapS,
  TotalPages,
  TotalPagesCount
} from './companiesS';
import { TableIconsWrapS } from '../../common/table/TableS';
import { PageTitleVioletS } from '../adminLayout/adminCommonStyles';

export const ActionIcons = connect(
  null,
  {
    setModalStateFor: modalsActions.setModalStateFor,
    updateCompanyStatus: companyActions.updateCompanyStatus,
    removeCompanyFully: companyActions.removeCompanyFully
  })(
  ({ setModalStateFor, isCompanyInactive, companyId, updateCompanyStatus }) =>
    <TableIconsWrapS>
      {
        isCompanyInactive ? (
          <IconBtnS
            iconName="redo"
            color="green"
            bgColorHover="greenAlpha"
            title={i18n.t('dashBoard.buttons.deleteTooltip')}
            onClick={() => updateCompanyStatus('active', companyId)}
          />
        ) : (
          <Link to={`/admin/company-profile/${companyId}`}>
            <IconBtnS
              iconName="edit"
              color="greyYellow"
              bgColorHover="tulipTreeAlpha"
              title={i18n.t('dashBoard.buttons.editTooltip')}
            />
          </Link>
        )
      }
      <IconBtnS
        iconName="trash-alt"
        color="red"
        bgColorHover="redAlpha"
        title={i18n.t('dashBoard.buttons.deleteTooltip')}
        onClick={
          isCompanyInactive
            ? () => setModalStateFor(modalsConstants.MODAL_TYPES.COMPANY_DELETE_CONFIRMATION, { open: true, companyId })
            : () => updateCompanyStatus('inactive', companyId)
        }
      />
    </TableIconsWrapS>
);

export const COMPANY_STATE = {
  ACTIVE: 'active',
  DELETED: 'deleted',
};

const tabsBtn = [
  { filterName: COMPANY_STATE.ACTIVE, textKey: 'adminPanel.companies.buttons.activeCompany' },
  { filterName: COMPANY_STATE.DELETED, textKey: 'adminPanel.companies.buttons.deleteCompany' }
];

const CompaniesComponent = ({
  itemsPerPage,
  setItemsPerPage,
  setCompaniesStateType,
  companiesStateType,
  changeCompanyStatus,
  companies,
  push,
  location,
  amount,
  companyColumns,
  setPage,
  page,
  removeCompanyFully,
}) => {
  return (
    <>
      <PageTitleVioletS>
        <Trans i18nKey="adminPanel.companies.companyProfile"/>
      </PageTitleVioletS>

      {/*<CompanyDeleteConfirmationModal />*/}
      <ConfirmationModal
        confirmationName={MODAL_TYPES.COMPANY_DELETE_CONFIRMATION}
        onApproved={(companyId) => removeCompanyFully(companyId)}
      />

      <NavTabs aItems="center">
        {tabsBtn.map(({ filterName, textKey }) => (
          <NavTabsBtn
            key={filterName}
            fz="mediumText"
            padding="6px 15px"
            fontFamily="normal"
            onClick={() => {
              setCompaniesStateType(filterName);
              push(location.pathname, { companiesType: filterName });
            }}
            active={filterName === companiesStateType}
          >
            <Trans i18nKey={textKey}/>
          </NavTabsBtn>
        ))}
      </NavTabs>

      <Table
        columns={companyColumns}
        data={companies}
      />

      <NavWrapS aItems="center" justify="space-between">

        <SelectItemsPerPage
          options={Object.values(ITEMS_PER_PAGE)}
          itemsPerPage={itemsPerPage}
          onChangeItemsPerPage={changedTo => setItemsPerPage(changedTo)}
        />

        <Pagination
          itemsPerPage={itemsPerPage}
          itemsAmount={amount}
          pageToRender={page}
          onPageChanged={page => setPage(page)}
        />

        <TotalPages>
          <Trans i18nKey="adminPanel.companies.totalCompany"/>
          <TotalPagesCount>{amount}</TotalPagesCount>
        </TotalPages>
      </NavWrapS>
    </>
  );
};

export default CompaniesComponent;
