import React from 'react';
import * as R from 'ramda';
import { setPropTypes, withStateHandlers } from 'recompose';
import { compose } from 'redux';
import PropTypes from 'prop-types'
import styled from 'styled-components';

import { IconBtnS } from './IconButton';

const PaginationWrapS = styled.div`
    display: flex;
    align-items: center;
`;

const PaginationContainer = compose(
    withStateHandlers(({ pageToRender }) => ({
        currentPage: pageToRender // drop this later
    }),
    {
        nextPage: ({ currentPage }, {onPageChanged}) => () => {
            onPageChanged(currentPage++);
            return { currentPage: currentPage++ }
        },
        prevPage: ({ currentPage }, {onPageChanged}) => () => {
            onPageChanged(currentPage--);
            return { currentPage: currentPage-- }
        },
        /* eslint-disable no-empty-pattern */
        onChangePage: ({}, {onPageChanged}) => currentPage => {
            onPageChanged(currentPage);
            return { currentPage }
        },
    },
    setPropTypes({
        itemsPerPage: PropTypes.number.isRequired,
        itemsAmount: PropTypes.number.isRequired,
        onPageChanged: PropTypes.func.isRequired,
        pageToRender: PropTypes.number.isRequired,
    })
));

const Pagination = ({ prevPage, nextPage, onChangePage, pageToRender, itemsPerPage, itemsAmount }) => {

  const totalPages = Math.ceil(itemsAmount/itemsPerPage) + 1;
  const firstRangeNumber = pageToRender <= 2 ? 1 : pageToRender - 2;
  const lastRangeNumber = totalPages <= 5 ? totalPages : firstRangeNumber + 5 < totalPages ? firstRangeNumber + 5 : totalPages;

  return (
        <PaginationWrapS>
            <IconBtnS
                iconName="angle-left"
                color="greyIcon"
                bgColor="greyButton"
                colorHover="white"
                bgColorHover="red"
                sizeCircle="34px"
                onClick={prevPage}
                disabled={pageToRender === 1}
            />
            {
                R.range(firstRangeNumber, lastRangeNumber).map(number => (
                    <IconBtnS
                        key={number}
                        number={number}
                        color="greyIcon"
                        bgColor="greyButton"
                        colorHover="white"
                        bgColorHover="red"
                        sizeCircle="34px"
                        active={pageToRender === number}
                        onClick={() => onChangePage(number)}
                    />
                ))
            }
            <IconBtnS
                iconName="angle-right"
                color="greyIcon"
                bgColor="greyButton"
                colorHover="white"
                bgColorHover="red"
                sizeCircle="34px"
                onClick={nextPage}
                disabled={pageToRender === totalPages}
            />
        </PaginationWrapS>
    )
};

export default PaginationContainer(Pagination)
