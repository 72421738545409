import {
  APPLY_FILTERS,
  APPLY_SELECTS_VALUES_TO_FILTERS,
  RESET_FILTERS,
  SET_ALL_FILTER_VALUE,
  SET_FILTER,
  SET_ZIP_FILTER,
  SET_SEARCH_STRING,
  SET_SELECT_OPTION,
  SET_SORT_VALUE,
} from './companyFilterConstants';

import { companySorts } from '../../app/constants/sorts';
import { filterNames } from '../../app/constants/filters';

const initialState = {
  sort: companySorts.POPULAR.value,
  search: '',
  filter: {
    [filterNames.KANTONES]: [],
    [filterNames.LANGUAGES]: [],
    [filterNames.MEMBER]: [],
    [filterNames.SERVICES]: [],
    [filterNames.ZIP]: [],
  },
  selects: {
    [filterNames.KANTONES]: null,
    [filterNames.SERVICES]: [],
    [filterNames.CITY]: null,
    [filterNames.ZIP]: null
  }
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_FILTER: {
      const filterData = state.filter[payload.filterName];
      return {
        ...state,
        filter: {
          ...state.filter,
          [payload.filterName]: filterData.includes(payload.value)
            ?  filterData.filter(i => i !== payload.value)
            : [ ...filterData, payload.value ]
        }
      }
    }
    case SET_ZIP_FILTER: {
      return {
        ...state,
        filter: {
          ...state.filter,
          [filterNames.ZIP]: payload
        }
      }
    }
    case SET_SELECT_OPTION: {
      return {
        ...state,
        selects: {
          ...state.selects,
          ...payload
          //[payload.filterName]: payload.value
        }
      }
    }
    case SET_ALL_FILTER_VALUE: {
      return {
        ...state,
        filter: {
          ...state.filter,
          [payload.filterName]: payload.values,
        }
      }
    }
    case APPLY_FILTERS: {
      return {
        ...state,
        filter: payload
      }
    }
    case APPLY_SELECTS_VALUES_TO_FILTERS: {
      return {
        ...state,
        filter: {
          ...state.filter,
          ...payload
        },
        selects: initialState.selects
      }
    }
    case SET_SEARCH_STRING: {
      return {
        ...state,
        search: payload
      }
    }
    case SET_SORT_VALUE: {
      return {
        ...state,
        sort: payload
      }
    }
    case RESET_FILTERS: {
      return {
        ...state,
        filter: {
          ...initialState.filter
        }
      }
    }
    default:
      return state;
  }

}
