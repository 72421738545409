import { combineReducers } from 'redux';

import { routerReducer as router } from 'react-router-redux';
import { authReducer, } from '../modules/auth';
import { appReducer } from '../modules/app';
import { companyReducer } from '../modules/company';
import { companyFilterReducer } from '../modules/companyFilter';
import { reviewReducer } from '../modules/review';
import { profileReducer } from '../modules/profile';
import { modalsReducer } from '../modules/modals';

export default combineReducers({
  router,
  auth: authReducer,
  app: appReducer,
  company: companyReducer,
  companyFilter: companyFilterReducer,
  review: reviewReducer,
  profile: profileReducer,
  modals: modalsReducer,
});
