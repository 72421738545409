import React from 'react';
import styled from 'styled-components';
import { compose, lifecycle, withState } from 'recompose';
import i18n from 'i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getScreenSize, getThemeColor, getThemeSize } from "../../../utils/theme";
import { DisplayFlexS } from "../../../utils/stylesHelpers";
import { Text } from "../common";
import { setItem } from "../../../utils/helpers/localStorage";
import changeLanguageForDate from "../../../utils/helpers/changeLanguageForDate";


const LanguageSwitcherWrap = styled.div`
  margin-right 25px;
  border-radius: 3px;
  background-color: ${getThemeColor(['greyLight'])};
  
  cursor: pointer;
  
  @media (max-width: ${getScreenSize(['medium'])}) {
    margin: 10px 0 0;
    padding: 5px;
  }
`;

const LanguageHeader = styled(DisplayFlexS)`
  padding: 0 10px 0 15px;
  
  color: ${getThemeColor(['violet'])};  
  font-size: ${getThemeSize(['bigText'])};
`;

const DropdownLanguageSwitcherS = styled.div`
  display: block;
  position: absolute;
  top: 55px;
  
  min-width: 110px;
  margin: 0 auto;
  padding: 20px 0 10px;
  
  border-radius: 3px;
  background-color: white;
  box-shadow: 1px 0px 1px rgba(0,0,0,.25);
  
  @media (max-width: ${getScreenSize(['medium'])}) {
     position: static;
     
     display: flex;
     flex-direction: column;
     justify-content: flex-start;
     
     margin: 0 auto 0 0;
     padding: 0;
     
     box-shadow: none;
  }
`;

const LanguageItems =  styled(DisplayFlexS)`
  padding: 5px 15px;
  
  color: ${getThemeColor(['primary'])};
  font-size: ${getThemeSize(['normalText'])};
  
  &:hover {
    color: ${getThemeColor(['white'])};
    background-color: ${getThemeColor(['violet'])};
  }
`;


const LANGUAGES = [
  {
    name: 'Deutsch',
    key: 'de'
  },
  {
    name: 'English',
    key: 'en'
  }
];

const LanguageSwitcher = ({ showItems, setShowItems }) => (
  <LanguageSwitcherWrap onClick={() => setShowItems(!showItems)}>
    <LanguageHeader aItems='center' alignItemsScreenMedium='center'>
      <Text fontFamily='bold' size='bigText' color='violet' style={{ marginRight: 10 }}>
        {i18n.language.toUpperCase()}
      </Text>
        <FontAwesomeIcon  size="2x" icon='angle-down'/>
    </LanguageHeader>
    {
      showItems ? (
        <DropdownLanguageSwitcherS>
          {
            LANGUAGES.map( lang => (
              <LanguageItems
                key={lang.key}
                onClick={() => {
                  if (i18n.language !== lang.key) {
                    i18n.changeLanguage(lang.key);
                    setItem('i18nLanguage', lang.key);
                    changeLanguageForDate();
                    document.location.reload(true);
                  }
                }}
              >
                {lang.name}
              </LanguageItems>
            ))
          }
        </DropdownLanguageSwitcherS>
      ) : null
    }
  </LanguageSwitcherWrap>
);

export default compose(
  withState('showItems', 'setShowItems', false),
  lifecycle ({
    componentDidUpdate(prevProps) {
      if (prevProps.showItems !== this.props.showItems && this.props.showItems) {
        document.addEventListener('click', () => {
          this.props.setShowItems(false)
        }, {
          once: true
        })
      }
    }
  })
)(LanguageSwitcher);
