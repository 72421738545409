import {
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  FETCH_PROFILE_REVIEWS_ADD_SUCCESS,
  FETCH_PROFILE_REVIEWS_ERROR,
  FETCH_PROFILE_REVIEWS_INIT_SUCCESS,
  FETCH_PROFILE_REVIEWS_REQUEST,
  RESET_PROFILE_DATA,
} from "./profileConstants";

const initialState = {
  reviews: [],
  countReview: 0,
  loading: false,
  errors: null,
};

export default function reducer(state=initialState, action) {
  const { error, type, payload } = action;

  switch (type) {
    case FETCH_PROFILE_REVIEWS_REQUEST:
    case CHANGE_PASSWORD_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case FETCH_PROFILE_REVIEWS_INIT_SUCCESS: {
      return {
        ...state,
        loading: false,
        reviews: payload.list,
        countReview: payload.count,
      }
    }
    case FETCH_PROFILE_REVIEWS_ADD_SUCCESS: {
      return {
        ...state,
        loading: false,
        reviews: [...state.reviews , ...payload.list],
        countReview: payload.count,
      }
    }
    case RESET_PROFILE_DATA: {
      return initialState;
    }
    case CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }
    case FETCH_PROFILE_REVIEWS_ERROR:
    case CHANGE_PASSWORD_ERROR: {
      return {
        ...state,
        loading: false,
        error
      }
    }
    default:
      return state;
  }
}

