import React from 'react';
import { MODAL_TYPES } from '../../../../modules/modals/modalsConstants';
import { Trans } from "react-i18next";
import { DisplayFlexS } from "../../../../utils/stylesHelpers";
import { GreyBtnS, PrimaryButtonS, Text } from "../../common";
import styled from "styled-components";
import { getThemeColor, getThemeSize } from "../../../../utils/theme";

export const ModalTitle = styled(Text)`
   color: ${getThemeColor(['violet'])};
   font-size: ${getThemeSize(['headers', 'companyItemName'])};
   text-align: center;
   margin-bottom: 50px;
`;

const BasicLayout = (setModalStateFor, modalName, onApproved, entityId) => (
  <>
    <ModalTitle  weight="bold">
      <Trans i18nKey={`adminPanel.modals.${modalName}.text`}  />
    </ModalTitle>

    <DisplayFlexS aItems="center" justify="space-between">
      <GreyBtnS
        onClick={() => setModalStateFor(modalName, { open: false })}
        padding="10px 50px"
      >
        <Trans i18nKey={`adminPanel.modals.${modalName}.cancelBtn`} />
      </GreyBtnS>
      <PrimaryButtonS padding="10px 50px" onClick={() => {
        onApproved(entityId);
        setModalStateFor(modalName, { open: false });
      }}>
        <Trans i18nKey={`adminPanel.modals.${modalName}.approveBtn`} />
      </PrimaryButtonS>
    </DisplayFlexS>
  </>
);

export default {
  [MODAL_TYPES.POST_COMMENT_CONFIRMATION]: BasicLayout,
  [MODAL_TYPES.DELETE_COMMENT_CONFIRMATION]: BasicLayout,
  [MODAL_TYPES.COMPANY_DELETE_CONFIRMATION]: BasicLayout,
  [MODAL_TYPES.DELETE_RATING_CONFIRMATION]: BasicLayout,
}
