import { compose, withHandlers, withReducer } from 'recompose';
import { connect } from 'react-redux';
import { remove } from 'ramda';

import ModalFilterComponent from './filtersModal';
import { withLanguageOnChange } from "../../../../utils/enhancers/withLanguageChange";
import { companyFilterActions } from '../../../../modules/companyFilter';
import { filterNames } from '../../../constants/filters';

const { applyFilters } = companyFilterActions;

const enhance = compose(
  connect(({ companyFilter }) => ({ filters: companyFilter.filter }), { applyFilters }),
  withLanguageOnChange,
  withReducer(
    'filtersLocal',
    'dispatchFilterLocalValue',
    (state, { filterName, value, type }) => {
      if (type === 'RESET') {
        return {
          [filterNames.KANTONES]: [],
          [filterNames.LANGUAGES]: [],
          [filterNames.MEMBER]: [],
          [filterNames.SERVICES]: [],
        }
      }

      const filterState = state[filterName];

      return { ...state, [filterName]: filterState.includes(value)
        ? remove(filterState.indexOf(value), 1, filterState)
        : [ ...filterState, value ]
      }
    },
    ({ filters }) => filters),
  withHandlers({
    onApply: ({ filtersLocal, applyFilters, toggleModal }) => (e) => {
      applyFilters(filtersLocal);
      toggleModal(false);
    }
  })
);

export default enhance(ModalFilterComponent);
