import { appName } from '../../config';

export const moduleName = 'company';
const prefix = `${appName}/${moduleName}`;

// Const
export const LAST_CREATES_COMPANY_REQUEST = `${prefix}/LAST_CREATES_COMPANY_REQUEST`;
export const LAST_CREATES_COMPANY_SUCCESS = `${prefix}/LAST_CREATES_COMPANY_SUCCESS`;
export const LAST_CREATES_COMPANY_ERROR = `${prefix}/LAST_CREATES_COMPANY_ERROR`;

export const FETCH_COMPANIES_REQUEST = `${prefix}/FETCH_COMPANIES_REQUEST`;
export const FETCH_COMPANIES_INIT_SUCCESS = `${prefix}/FETCH_COMPANIES_INIT_SUCCESS`;
export const FETCH_COMPANIES_ADD_SUCCESS = `${prefix}/FETCH_COMPANIES_ADD_SUCCESS`; // use when already has items in reducer
export const FETCH_COMPANIES_ERROR = `${prefix}/FETCH_COMPANIES_ERROR`;

export const FETCH_DELETED_COMPANIES_REQUEST = `${prefix}/FETCH_DELETED_COMPANIES_REQUEST`;

export const FETCH_COMPANIES_BY_ADMIN = `${prefix}/FETCH_COMPANIES_BY_ADMIN`;

// one company
export const FETCH_COMPANY_ITEM_REQUEST = `${prefix}/FETCH_COMPANY_ITEM_REQUEST`;
export const FETCH_COMPANY_ITEM_SUCCESS = `${prefix}/FETCH_COMPANY_ITEM_SUCCESS`;
export const FETCH_COMPANY_ITEM_ERROR = `${prefix}/FETCH_COMPANY_ITEM_ERROR`;

// update company
export const UPDATE_COMPANY_REQUEST = `${prefix}/UPDATE_COMPANY_REQUEST`;
export const UPDATE_COMPANY_SUCCESS = `${prefix}/UPDATE_COMPANY_SUCCESS`;
export const UPDATE_COMPANY_ERROR = `${prefix}/UPDATE_COMPANY_ERROR`;

// update logo company
export const UPDATE_COMPANY_LOGO_REQUEST = `${prefix}/UPDATE_COMPANY_LOGO_REQUEST`;
export const UPDATE_COMPANY_LOGO_SUCCESS = `${prefix}/UPDATE_COMPANY_LOGO_SUCCESS`;
export const UPDATE_COMPANY_LOGO_ERROR = `${prefix}/UPDATE_COMPANY_LOGO_ERROR`;

// update verified company

export const UPDATE_VERIFIED_COMPANY_REQUEST = `${prefix}/UPDATE_VERIFIED_COMPANY_REQUEST`;
export const UPDATE_VERIFIED_COMPANY_SUCCESS = `${prefix}/UPDATE_VERIFIED_COMPANY_SUCCESS`;
export const UPDATE_VERIFIED_COMPANY_ERROR = `${prefix}/UPDATE_VERIFIED_COMPANY_ERROR`;

// remove logo company
export const REMOVE_COMPANY_LOGO_REQUEST = `${prefix}/REMOVE_COMPANY_LOGO_REQUEST`;
export const REMOVE_COMPANY_LOGO_SUCCESS = `${prefix}/REMOVE_COMPANY_LOGO_SUCCESS`;
export const REMOVE_COMPANY_LOGO_ERROR = `${prefix}/REMOVE_COMPANY_LOGO_ERROR`;

export const ADD_COMPANY_VISIT = `${prefix}/ADD_COMPANY_VISIT`;
export const CLEAR_COMPANY_LIST = `${prefix}/CLEAR_COMPANY_LIST`;

export const UPDATE_COMPANY_STATUS_REQUEST = `${prefix}/UPDATE_COMPANY_STATUS`;
export const COMPANY_STATUS_INACTIVE_SUCCESS = `${prefix}/COMPANY_STATUS_INACTIVE_SUCCESS`;
export const COMPANY_STATUS_ACTIVE_SUCCESS = `${prefix}/COMPANY_STATUS_ACTIVE_SUCCESS`;
export const UPDATE_COMPANY_STATUS_ERROR = `${prefix}/UPDATE_COMPANY_STATUS_ERROR`;

export const DELETE_COMPANY_REQUEST = `${prefix}/DELETE_COMPANY_REQUEST`;
export const DELETE_COMPANY_SUCCESS = `${prefix}/DELETE_COMPANY_SUCCESS`;
export const DELETE_COMPANY_ERROR = `${prefix}/DELETE_COMPANY_ERROR`;

