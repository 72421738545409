import styled from 'styled-components';

import { Divider, TextInput } from '../../common';
import { getThemeColor, getThemeSize } from '../../../../utils/theme';

import redChat from '../../../assets/imgs/icons/chat-red.svg';

export const FiltersWrapS = styled.div`
  width: 100%;
  margin-bottom: 10px;
  padding-right: 25px;
`;

export const FilterWrapS = styled.div`
  margin-bottom: 15px;
  
  .error-message {
    color: ${getThemeColor(['red'])};
    font-size: ${getThemeSize(['bigText'])};
    
    &:before {
      content: '';
      display: inline-block;
      transform: translateY(28%);
      margin: -15px 8px 0 0;
      width: 23px;
      height: 22px;
      background-image: url(${redChat});
    }
  }
`;

export const ArrowIconWrap = styled.div`
  display: flex;
  justify-content: center;
  color: ${getThemeColor(['red'])};
  border-radius: 50%;
  height: 30px;
  width: 30px;
  transition: transform .3s ease-in-out; 
  transform: ${({ isOpen }) => isOpen ? 'rotateX(0deg)' : 'rotateX(180deg)'} translateX(10px);
  margin-top: -5px;
  
  &:hover{
    background-color: ${getThemeColor(['grayBlue'])};
    cursor: pointer;
  }
`;

export const StyledDivider = styled(Divider)`
  margin-bottom: 10px;
  border-top: 1px solid ${getThemeColor(['grayBlue'])};
`;

export const OptionsWrapS = styled.div`
  margin-top: 5px;
  margin-bottom: 10px;
  transition: height 0.5s;
  max-height: 180px;
  overflow-y: auto;
`;

export const FilterOptionContainer = styled.div`
  align-content: center;
  display: flex;
  margin-bottom: 5px;
  
  span {
    font-size: ${getThemeSize(['normalText'])};
    color: ${getThemeColor(['primary'])};
    margin-left: 8px;
    
    &:hover {
      color: ${getThemeColor(['violet'])};
      cursor: pointer;
    }
  }
`;

export const SearchInput = styled(TextInput)`
  border: 1px solid ${getThemeColor(['greyButton'])};
  margin-bottom: 10px;
`;