/*
*  put here some vales which is not changed with the time like options in select for homepage
* */

export const filterNames = {
  KANTONES: 'kantones',
  LANGUAGES: 'languages',
  SERVICES: 'services',
  MEMBER: 'members',
  CITY: 'city',
  ZIP: 'zip',
  LOCATIONS: 'locations'
};

export const services = [
  'Laufende Verbuchungen',
  'Geschäftsabschluss',
  'Mehrwertsteuer',
  'Lohnbuchhaltung',
  'Revision',
  'Steuerberatung Schweiz',
  'Steuerberatung International',
  'Steuererklärungen',
  'Juristische Beratung',
  'Unternehmensberatung',
  'Domizil-Services',
  'Firmengründungen',
  'Immobilienverwaltung',
  'Vermögensverwaltung'
];

/* TODO: rewrite it to get it from server. On server we have almost the same list. */
export const financialAccountingServices = ['Laufende Verbuchungen', 'Geschäftsabschluss', 'Mehrwertsteuer', 'Lohnbuchhaltung', 'Revision', 'Wirtschaftsprüfung'];
export const consultationServices = ['Steuerberatung Schweiz', 'Steuerberatung International', 'Steuererklärungen', 'Juristische Beratung', 'Unternehmensberatung', 'Erbschaftsberatung'];
export const miscellaneousServices = ['Domizil-Services', 'Firmengründungen', 'Immobilienverwaltung', 'Vermögensverwaltung'];

export const industries = [
  'Baugewerbe',
  'Industrie',
  'Landwirtschaft',
];

export const languages = [
  'Deutsch',
  'Englisch',
  'Französisch',
  'Italienisch',
  'Russisch',
  'Holländisch',
  'Spanisch',
  'Portugiesisch',
  'Arabisch',
];


export const software = [
  'Bexio',
  'Banana',
  'Office Maker',
  'LoopsFinanz',
  'Klara',
  'Abacus',
  'Sage',
  'Comatic',
  'CashCtrl',
];

export const members = [
  'Treuhand Suisse',
  'Veb.ch',
  'EXPERTsuisse',
];
