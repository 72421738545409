import React from 'react';
import { compose, withState, lifecycle, withHandlers, withProps } from 'recompose';
import { connect } from 'react-redux';
import i18n from 'i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { uniq, omit, equals, path } from 'ramda';

import { companyFilterActions } from '../../../../modules/companyFilter';

import {
  ArrowIconWrap,
  FiltersWrapS,
  FilterWrapS,
  OptionsWrapS,
  StyledDivider,
  FilterOptionContainer,
  SearchInput
} from './companiesFiltersS';
import { Text, CheckBox } from '../../common';

import { DisplayFlexS } from '../../../../utils/stylesHelpers';
import { eventGA } from "../../../../utils/GA";
import searchFn from '../../../../utils/helpers/searchFn';

import { SERVICES_NAMES } from './constants';

import { filterNames } from '../../../constants/filters';
import { categoryGA } from "../../../constants/googleAnalytics";

const { setSort, setFilter, setZipFilter } = companyFilterActions;

/*Small helpers*/
const mapZip = ({ zip }) => zip;
/* THIS isn`t best solutions but now we have two language it languages list will be extended this have to be updated as well. */
const noAlleOption = i => !['Alle', 'All'].includes(i);

const enhancer = compose(
  connect(({ companyFilter, app }) => ({
    filtersState: companyFilter.filter,
    activeFilters: app.activeFilters
  }), { setSort, setFilter, setZipFilter }),
  withProps({ zipSearchRef: React.createRef() }),
  withState('filtersViewState', 'setFiltersViewState', {
    [filterNames.MEMBER]: false,
    [filterNames.LANGUAGES]: false,
    [filterNames.SERVICES]: false,
    [filterNames.KANTONES]: false,
    [filterNames.ZIP]: false,
  }),
  withState('options', 'setOptions', ({ activeFilters }) => ({
    ...omit([filterNames.LOCATIONS], activeFilters),
    [filterNames.ZIP]: [], // initialization in componentDidUpdate
  })),
  withState('errors', 'setErrors',  Object.values(filterNames).map(key => ({ key: null }))),
  withHandlers({
    /* This methods will get options in proper format.
      *  Logic is next
      * We have designed zip search with ** options what means that:
      * 1. In default case have have to render options in next format: 10**, 11**, 12** ...
      * 2. If use type one use that same logic as for 1 point
      * 3. If use typed 2 values renter options : 10**, 1000, 1001, 1002 ...
      * 4. If use type 3 or 4 numbers render all matched options.
      *  */
    getZipOptions: ({ filtersState, activeFilters, options, zipSearchRef }) => (hasChangedZipState) => {
      const searchStr = path(['current', 'value'], zipSearchRef);
      const isAllKantonesSelected = filtersState[filterNames.KANTONES].length === activeFilters[filterNames.KANTONES].length;

      const allZipsUniq = () => isAllKantonesSelected || !filtersState[filterNames.KANTONES].length // Maybe if we will have issues with perfomance look at this mybe will try to use memoization.
        ? uniq(activeFilters[filterNames.LOCATIONS].map(mapZip))
        : uniq(activeFilters[filterNames.LOCATIONS]
          .filter(({ kanton }) => filtersState[filterNames.KANTONES].includes(kanton))
          .map(mapZip));

      // get value in XX** format
      const getOptionsWithTwoStars = () => uniq(allZipsUniq().map(o => `${o.slice(0, 2)}**`));

      if (searchStr) {
        if (searchStr.length === 1) {
          return getOptionsWithTwoStars()
        } else if (searchStr.length === 2) {
          return getOptionsWithTwoStars().includes(`${searchStr}**`)
            ? [ `${searchStr}**`, ...allZipsUniq().filter(o => new RegExp(`^${searchStr}`).test(o)) ]
            : allZipsUniq().filter(o => new RegExp(`^${searchStr}`).test(o))
        } else {
          return hasChangedZipState ? options[filterNames.ZIP] : allZipsUniq();
        }
      } else {
        const optionsWithStars = filtersState[filterNames.ZIP].filter(zip => /\d\d[*][*]/.test(zip));
        return [...filtersState[filterNames.ZIP], ...getOptionsWithTwoStars().filter(z => !optionsWithStars.includes(z))] // have to drop values which was in a list
      }
    }
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { activeFilters, filtersState, options, setOptions, getZipOptions } = this.props;
      const didFilterChange = key => !equals(filtersState[key], prevProps.filtersState[key]);

      if (activeFilters !== prevProps.activeFilters) {
        setOptions({
          ...omit([filterNames.LOCATIONS], activeFilters),
          [filterNames.ZIP]: getZipOptions(),
        });
      }

      if ([filterNames.KANTONES, filterNames.ZIP].find(didFilterChange)) { // on change kanton update zip codes list
        setOptions({
          ...options,
          [filterNames.ZIP]: getZipOptions(true) // true is indicator for state changing
        });
      }
    }
  })
);

const CompaniesFilters = ({
  filtersViewState,
  activeFilters,
  setFiltersViewState,
  setFilter,
  filtersState,
  options,
  setOptions,
  errors,
  setErrors,
  getZipOptions,
  setZipFilter,
  zipSearchRef
}) => {
  const toggleFilter = (filterName) => (e) => {
    setFiltersViewState({ ...filtersViewState, [filterName]: !filtersViewState[filterName] })
    if (errors[filterName] && filtersViewState[filterName]) { // reset states for filter in case filter is closing
      setErrors({
        ...errors,
        [filterName]: null,
      });
      if (filterName === filterNames.ZIP) {
        zipSearchRef.current.value = '';
        setOptions({
          ...options,
          [filterName]: getZipOptions()
        })
      } else {
        setOptions({
          ...options,
          [filterName]: activeFilters[filterName]
        })
      }
    }

    e.preventDefault();
  };

  const doSearch = (filterName, translatePath, searchFiledData) => ({ target: { value } }) => {
    let newOptions;

    if (filterName === filterNames.ZIP) { // handle zip code search case.
      newOptions = searchFn(value, getZipOptions());
    } else { // handle other cases.
      const translatedOptions = activeFilters[filterName]
        .filter(noAlleOption)
        .map(option => ({
          value: option,
          label: i18n.t([`${translatePath}.${option}`, option])
        }));

      newOptions = value
        ? searchFn(value, translatedOptions, 'label').map(e => e.value)
        : activeFilters[filterName];
    }

    if (!newOptions.length) {
      setErrors({
        ...errors,
        [filterName]: i18n.t(searchFiledData.errorMessageOnNoFound)
      })
    } else if (errors[filterName] && newOptions.length) {
      setErrors({
        ...errors,
        [filterName]: null,
      })
    }

    setOptions({
      ...options,
      [filterName]: newOptions
    })
  }

  return (
    <FiltersWrapS>
      {
        SERVICES_NAMES.map(({
          nameTrans,
          filterName,
          translatePath,
          searchFieldData,
        }) => (
          <FilterWrapS key={filterName}>
            <DisplayFlexS
              justify="space-between"
              justifyScreenMedium="space-between"
              style={{ marginBottom: '10px' }}
            >
              <Text fontFamily='bold' color='violet' size='bigText'>
                {i18n.t(nameTrans)}
                {
                  filtersState[filterName].length
                    ? ` (${filtersState[filterName].filter(i => !['Alle', 'All'].includes(i) && !i.includes('*')).length})`
                    : ''
                }
              </Text>
              <ArrowIconWrap
                isOpen={filtersViewState[filterName]}
                onClick={toggleFilter(filterName)}
              >
                <FontAwesomeIcon size="2x" icon='angle-up' />
              </ArrowIconWrap>
            </DisplayFlexS>

            {
              filtersViewState[filterName] &&
              <>
                {
                  searchFieldData &&
                  <SearchInput
                    placeholder={i18n.t(searchFieldData.placeholder)}
                    isError={!!errors[filterName]}
                    error={<span className="error-message">{errors[filterName]}</span>}
                    onChange={doSearch(filterName, translatePath, searchFieldData)}
                    onFocus={doSearch(filterName, translatePath, searchFieldData)}
                    {...(filterName === filterNames.ZIP ? { ref: zipSearchRef } : {})}
                  />
                }
                <OptionsWrapS>
                  {
                    options[filterName].map(option => (
                      <FilterOptionContainer key={option}>
                        <label>
                          <CheckBox
                            onChange={() => {
                              filterName === filterNames.ZIP ? setZipFilter(option) : setFilter({ filterName, value: option })
                              eventGA({
                                category: categoryGA.FILTER,
                                action: filterName,
                                label: option,
                              })
                            }}
                            checked={filtersState[filterName].includes(option)}
                          />
                          <span>{ i18n.t([`${translatePath}.${option}`, option]) }</span>
                        </label>
                      </FilterOptionContainer>
                    ))
                  }
                </OptionsWrapS>
              </>
            }
            <StyledDivider />
          </FilterWrapS>
        ))
      }
    </FiltersWrapS>
  )};

export default enhancer(CompaniesFilters);