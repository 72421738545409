import React from 'react';
import { connect } from 'react-redux';
import { compose } from "recompose";

import { authModalLayouts } from '../../../constants/modals';
import { LoginLayout, RegistrationLayout, ResetPass, SendEmail } from './authModalLayouts';

import { toggleModal } from '../../../../modules/app/appActions';
import { cleanUpAuthErrors, resetPass, sendEmail } from '../../../../modules/auth/authActions';

import { CloseIconS, StyledModal } from '../modalCommonS';
import { withLanguageOnChange } from "../../../../utils/enhancers/withLanguageChange";

const AuthModal = ({ modal, toggleModal, auth, resetPass, sendEmail, cleanUpAuthErrors }) => (
  <StyledModal
    isOpen={modal.open}
    onEscapeKeydown={() => toggleModal({ open: false, layout: null })}
    afterClose={cleanUpAuthErrors}
  >
    <CloseIconS  onClick={() => toggleModal({ open: false, layout: null })} icon='times'/>
    { modal.layout === authModalLayouts.registration && <RegistrationLayout setModalStateTo={toggleModal} /> }
    { modal.layout === authModalLayouts.login &&  <LoginLayout setModalStateTo={toggleModal} /> }
    { modal.layout === authModalLayouts.resetPass && <ResetPass auth={auth} resetPass={resetPass} /> }
    { modal.layout === authModalLayouts.sendEmail && <SendEmail sendEmail={sendEmail} auth={auth} /> }
  </StyledModal>
);

export default compose(
  connect(({ app, auth }) => ({
    modal: app.authModal,
    auth,
  }),
  { toggleModal, resetPass, sendEmail, cleanUpAuthErrors }),
  withLanguageOnChange
)(AuthModal);
