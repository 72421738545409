import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';

import { getThemeColor } from '../../../utils/theme';
import { Text } from './Text';

export const ErrorWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${R.propOr(getThemeColor(['red']), 'color')};
  margin: ${R.propOr('0px', 'margin')};
  padding-bottom: ${R.propOr('0', 'marginBottom')};
`;

export const FieldError = ({
  icon,
  error,
  color = getThemeColor(['red']),
  weight,
  size,
  align,
  ...rest
}) => error ? (
  <ErrorWrapper {...rest}>
    <Text {...{ weight, color, size, align }}>
      {error}
    </Text>
  </ErrorWrapper>
) : null;
