import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleUp,
  faAngleDown,
  faAngleRight,
  faAngleLeft,
  faBars,
  faHandPaper,
  faSearch,
  faTimes,
  faChevronLeft,
  faPen,
  faPlusCircle,
  faUser,
  faSignOutAlt,
  faBriefcase,
  faEdit,
  faStickyNote,
  faTrashAlt,
  faRedo,
  faPlus,
  faComment,
  faComments,
  faBell,
  faUserFriends, faCheck,
  faStar,
  faArrowRight,
  faQuestionCircle
} from '@fortawesome/free-solid-svg-icons';

/*
*   in order to use font awesome icons just import FontAwesomeIcon comp
*   from @fortawwesome/react-fortawesome
*   and in icon prop just pass string which are responcible for icon
*   icon SHOULD be in library.
* */
export default () => {
  library.add(
    faHandPaper,
    faAngleUp,
    faAngleDown,
    faAngleLeft,
    faTimes,
    faSearch,
    faBars,
    faChevronLeft,
    faPen,
    faPlusCircle,
    faUser,
    faSignOutAlt,
    faBriefcase,
    faEdit,
    faStickyNote,
    faTrashAlt,
    faRedo,
    faPlus,
    faComment,
    faComments,
    faBell,
    faUserFriends,
    faAngleRight,
    faCheck,
    faStar,
    faArrowRight,
    faQuestionCircle
  ) // add other just passing like params
};
