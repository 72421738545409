import React from 'react';
import { arrayOf, element, func, oneOfType, shape, string } from 'prop-types';

import { IconBtnS } from '../IconButton';

import {
  TableHeadS,
  TableHeadTHS,
  TableRow,
  TableS,
  TableWrapS
} from './TableS';

const TableComponent = ({ columns, data, maxWidth }) => (
  <TableWrapS maxWidth={maxWidth}>
    <TableS>
      <TableHeadS>
        <tr>
          {
            columns.map((column) => (
              <TableHeadTHS key={column.title}>
                {column.title}
                {column.sort && (
                  <IconBtnS
                    iconName={column.sort.sortState === 1 ? 'angle-up' : 'angle-down'}
                    color="violet"
                    sizeCircle="20px"
                    displayBlock="inline-block"
                    onClick={column.sort.sortFunc}
                    direction={column.sort.sortState}
                  />
                )}
              </TableHeadTHS>
            ))
          }
        </tr>
      </TableHeadS>

      <tbody>
      {
        data.map(item => (
          <TableRow key={item + Math.random()}>
            {
              Object.values(item).map((key) => (
                <td key={item.name + Math.random()}>{key}</td>
              ))
            }
          </TableRow>

        ))
      }
      </tbody>
    </TableS>
  </TableWrapS>
);

TableComponent.propTypes = {
  columns: arrayOf(shape({
    title: oneOfType([string, element]).isRequired,
    sort: shape({ sortFunc: func, sortState: [-1, 0, 1] }),
  })).isRequired,
  data: arrayOf(shape({
    name: oneOfType([string, element]),
    data: oneOfType([string, element]),
    location: oneOfType([string, element]),
    phone: oneOfType([string, element]),
    email: oneOfType([string, element]),
    lastChange: shape({
      lastData: oneOfType([string, element]),
      byChange: oneOfType([string, element]),
    }),
    action: oneOfType([string, element]),
  })).isRequired,
  maxWidth: string
};

export default TableComponent;
