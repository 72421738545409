import { createGlobalStyle } from "styled-components";

import { getThemeColor, getThemeFont, getThemeSize } from '../../utils/theme';
import ScrollBarDown from '../assets/imgs/icons/ScrollBarDown.svg';
import ScrollBarUp from '../assets/imgs/icons/ScrollBarUp.svg';
import ScrollBarLeft from '../assets/imgs/icons/ScrollBarLeft.svg';
import ScrollBarRight from '../assets/imgs/icons/ScrollBarRight.svg';
import LatoBold from '../assets/fonts/Lato-Bold.ttf';
import LatoRegular from '../assets/fonts/Lato-Regular.ttf';

export default createGlobalStyle`
  @font-face {
    font-family: 'Lato-Bold';
    src: url(${LatoBold});
  }
  
  @font-face {
    font-family: 'Lato-Regular';
    src: url(${LatoRegular});
  }
  
   *,
  ::before,
  ::after {
    box-sizing: inherit;
  }

  html {
    line-height: 1.15;
    box-sizing: border-box;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    
    /* For Firefox */
    scrollbar-color: ${getThemeColor(['greyVioletLight'])} ${getThemeColor(['greyLight'])} ;
    
     /* For IE */
    scrollbar-face-color: ${getThemeColor(['greyVioletLight'])};
    scrollbar-arrow-color: ${getThemeColor(['greyViolet'])};
    scrollbar-track-color: ${getThemeColor(['greyLight'])};

    
  }

  html,
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }
  
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video,
  select {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: ${getThemeSize(['normalText'])};
    font-family: ${getThemeFont(['regular'])};
    color: ${getThemeColor(['primary'])};
  }
  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }
  #root,
  html,
  body {
    width: 100%;
    height: 100%;
    min-height: 600px;
    display: flex;
    flex-direction: column;
  }
  body {
    font-size: 100%;
    margin: 0;
    padding: 0;
    line-height: 1;
    flex-grow: 1;
    
    background-color: ${getThemeColor(['mainBackgroundColor'])};
  }
  
  
    
  /* Scrollbar for Chrome Opera and Safari */
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background: ${getThemeColor(['greyLight'])};
  }
  ::-webkit-scrollbar-button {
    height: 15px;
    width: 10px;
    background-size: 100%;
  }

  ::-webkit-scrollbar-button:horizontal:end:increment {
    background-image: url(${ScrollBarRight});
  }
  ::-webkit-scrollbar-button:horizontal:start:decrement {
    background-image: url(${ScrollBarLeft});
  }
  ::-webkit-scrollbar-button:vertical:end:increment {
    background-image: url(${ScrollBarDown});
  }
  ::-webkit-scrollbar-button:vertical:start:increment {
    display: none;
  }
  ::-webkit-scrollbar-button:vertical:start:decrement {
    background-image: url(${ScrollBarUp});
  }
  ::-webkit-scrollbar-button:vertical:end:decrement {
    display: none;
  }
  
 
  ::-webkit-scrollbar-thumb {
    background: ${getThemeColor(['greyVioletLight'])}; 
    border-radius: 3px;
  }

  ol,
  ul {
    list-style: none;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  html {
    box-sizing: border-box;
  }
  strong,
  b {
    font-weight: 500;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  a {
    text-decoration: none;
    color: #0152d9;
    &:hover {
      text-decoration: underline;
  }
  
  
}
`;
