import { filterNames } from '../../../constants/filters';

export const SERVICES_NAMES = [
  {
    filterName: filterNames.KANTONES,
    nameTrans: 'companies.filter.cantonTitle',
    searchFieldData: {
      placeholder: 'companies.filter.searchPlaceholders.kanton',
      errorMessageOnNoFound: 'companies.filter.searchErrorMessages.kantonNotFound'
    }
  },
  {
    filterName: filterNames.ZIP,
    nameTrans: 'companies.filter.zipTitle',
    searchFieldData: {
      placeholder: 'companies.filter.searchPlaceholders.zip',
      errorMessageOnNoFound: 'companies.filter.searchErrorMessages.zipNotFound'
    }
  },
  {
    filterName: filterNames.SERVICES,
    nameTrans: 'companies.filter.servicesTitle',
    translatePath: 'companyServices',
    searchFieldData: {
      placeholder: 'companies.filter.searchPlaceholders.service',
      errorMessageOnNoFound: 'companies.filter.searchErrorMessages.serviceNotFound'
    }
  },
  {
    filterName: filterNames.LANGUAGES,
    nameTrans: 'companies.filter.languagesTitle',
    translatePath: 'companyLanguages',
    searchFieldData: {
      placeholder: 'companies.filter.searchPlaceholders.language',
      errorMessageOnNoFound: 'companies.filter.searchErrorMessages.languageNotFound'
    }
  },
  {
    filterName: filterNames.MEMBER,
    nameTrans: 'companies.filter.memberTitle',
  },
];