import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { add, equals, multiply, propOr, slice } from 'ramda';

import {getScreenSize, getThemeColor, getThemeFont, getThemeSize} from '../../../../utils/theme';

import ArrowUrl from '../../../assets/imgs/icons/Arrow.svg';

const CarouselWrapS = styled.div`
   text-align: center;
   width: ${propOr('100%', 'width')} ;
   margin: 0 auto;
   padding-top: 10px;
`;

const CarouselHeader = styled.h2`
  font-family: ${getThemeFont(['bold'])};
  font-size: 22px;
  color: ${getThemeColor(['red'])};
  line-height: 30px;
  text-transform: uppercase;
  
  p {
    font-size: ${getThemeSize(['headers', 'secondary'])};
    color: ${getThemeColor(['darkerViolet'])}
  }
  
  @media (max-width: ${getScreenSize(['medium'])}) {
    p {
      font-size: 25px;
    }
  }
`;

const BlogHeaderWrap = styled(CarouselHeader)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 70px;
  font-size: ${getThemeSize(['headers', 'secondary'])};
  color: ${getThemeColor(['violet'])};
  
`;

const ControlsBtns = styled.div`
  text-align: right;
  margin-bottom: 20px;
  
  @media (max-width: ${getScreenSize(['medium'])}) {
    margin: 10px 10px 20px;
  }
`;

const ControlBtn = styled.button`
   background-color: ${getThemeColor(['white'])};
   padding: 9px 19px;   

   box-shadow: 1px 1px 2px rgba(0, 0, 0, .25);
   
   border: none;
   
   border-bottom-left-radius: ${({ left }) => left ? '3px': '0' };
   border-top-left-radius: ${({ left }) => left ? '3px': '0' };
   
   border-bottom-right-radius: ${({ left }) => !left ? '3px': '0' };
   border-top-right-radius: ${({ left }) => !left ? '3px': '0' };
   
   margin-right: ${({ left }) => left ? '7px' : '0' };
   
   &:hover {
    box-shadow: 2px 2px 4px rgba(0, 0, 0, .25);
    cursor: pointer;
   }
`;

const ItemsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  overflow-x: auto;
  width: 100%;
  
  min-height: ${({ typeCompanyCarousel }) => typeCompanyCarousel ? "362px" : "500px"}
  
  & div:last-child {
    margin-right: 0;
  }
`;

/*
*  According to btns write
* */
const CarouselComponent = ({
  items,
  title,
  header,
  moreBtn,
  numOfPage,
  switchCarousel,
  amountOfItems,
  itemsPerView,
  blogHeader,
  width,
  typeCompanyCarousel,
  hideNavigationBtn
}) => (
  <CarouselWrapS width={width} >
    {
      blogHeader ? (
        <BlogHeaderWrap>
          <Trans i18nKey={title} />
        </BlogHeaderWrap>
        ) : (
        <CarouselHeader>
          <Trans i18nKey={title} />
          <p>
            <Trans i18nKey={header} />
          </p>
        </CarouselHeader>
      )
    }

    <ControlsBtns>
      {
        !hideNavigationBtn ? (
          <>
            <ControlBtn
              disabled={equals(numOfPage, 0)}
              onClick={() => switchCarousel(true)}
              left
            >
              <img width='25px' src={ArrowUrl} alt="Previous"/>
            </ControlBtn>
            <ControlBtn
              disabled={equals(amountOfItems, multiply(numOfPage, itemsPerView))}
              onClick={() => switchCarousel()}
            >
            <img width='25px' src={ArrowUrl} alt="Next" style={{ transform: 'rotate(180deg)' }}/>
            </ControlBtn>
          </>
        ) : (null)
      }
    </ControlsBtns>

    <ItemsWrap typeCompanyCarousel={typeCompanyCarousel}>
      {
        items && slice(multiply(numOfPage, itemsPerView), add(itemsPerView, multiply(numOfPage, itemsPerView)), items).map(i => (i))
      }
    </ItemsWrap>

    {moreBtn}
  </CarouselWrapS>
);

export default CarouselComponent;
