import React from 'react';
import styled from 'styled-components';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';

import { routes } from '../../routes/routesConfig';

import { getScreenSize } from '../../../utils/theme';

import LogoImg from '../../assets/imgs/Logo.svg';
import { LinkS } from "./MenuItemLink";

const LogoS = styled.img`
   height: 31px;
   
   &:hover {
    cursor: pointer;
   }
   
  @media (max-width: ${getScreenSize(['medium'])}) {
      margin: 0 auto;
   }
  
`;

const Logo = ({ push, ...rest }) => (
  <LinkS to={routes.HOME}>
    <LogoS src={LogoImg} alt='logo' { ...rest } />
  </LinkS>
);

export default connect(null, { push })(Logo);
